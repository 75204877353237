<template>
  <div class="etsbuttongroup">
    <slot />
  </div>
</template>

<script>
export default {
  name: "EtsButtonGroup"
};
</script>

<style lang="scss" scoped>
.etsbuttongroup {
  margin: 0 4px 0;
  .ets-btn {
    margin: 0;
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 1px;
  }
  :first-child {
    border-radius: 2px 0 0 2px;
    border-left-width: 1px;
  }
  :last-child {
    border-radius: 0 2px 2px 0;
  }
}
</style>

<docs>
```jsx
<div>
  <ets-button-group>
    <ets-button icon="add" selected></ets-button>
    <ets-button icon="addcircle"></ets-button>
  </ets-button-group>
</div>
```
</docs>
