import EtsFocus from "@/directives/EtsFocus.js";
import EtsMask from "@/directives/EtsMask.js";
import EtsFragment from "@/directives/EtsFragment.js";

const Directives = [
  { name: "ets-focus", directive: EtsFocus },
  { name: "ets-mask", directive: EtsMask },
  { name: "ets-fragment", directive: EtsFragment }
];

export default Directives;
