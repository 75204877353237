<template>
  <div ref="ets-structure" class="ets-structure">
    <slot name="sidebar"></slot>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "EtsStructure"
};
</script>

<style scoped>
.ets-structure {
  display: flex;
}
.content {
  flex-grow: 1;
  overflow-x: auto;
}
</style>
