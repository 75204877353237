<template>
  <div id="app">
    <ets-nav-bar label="Papers" section="tech" />
    <ets-structure>
      <ets-sidebar slot="sidebar" :sections="sections"></ets-sidebar>
      <ets-header>
        <span>ETS Components</span>
        <p slot="subtitle">Página para probar componentes</p>
        <template slot="tools">
          <ets-button icon="send"></ets-button>
          <ets-button icon="file-excel"></ets-button>
          <ets-button icon="file-xml"></ets-button>
          <ets-button-group>
            <ets-button icon="add" selected></ets-button>
            <ets-button icon="addcircle"></ets-button>
            <ets-button icon="users"></ets-button>
            <ets-button icon="external"></ets-button>
          </ets-button-group>
          <ets-button icon="institutional"></ets-button>
        </template>
      </ets-header>
      <ets-page>
        <ets-button text="Add row" mode="main" @click="addRow" />
        <ets-button text="Cambio nombre tabla" mode="main" :loading="false" @click="text = !text" />
        <ets-alert v-if="showAlert" type="error" closable @close="showAlert = false">Error</ets-alert>
        <ets-table
          itemKey="recoID"
          :data="tableDataComputed"
          :columns="columns"
          class="list"
          :filterKey="text"
          :filtersEnabled="true"
          :checksEnabled="true"
          @selected-data="selectedDataFromChild"
        >
          <template slot="row" slot-scope="reco">
            <td class="text">
              <span>{{ reco.row.text }}</span>
            </td>
            <td class="text">
              {{ reco.row.title }}
            </td>
            <td class="text">{{ reco.row.img }}</td>
          </template>
        </ets-table>
        <!--<ets-table
          :data="tableDataComputed"
          :columns="columns"
          class="list"
          :filterKey="text"
          :filtersEnabled="true"
          :checksEnabled="true"
        />-->
      </ets-page>
    </ets-structure>
  </div>
</template>

<script>
import EtsNavBar from "@/components/Elements/NavBar/EtsNavBar";
import EtsSidebar from "@/components/Elements/EtsSidebar";
import EtsStructure from "@/components/Layout/EtsStructure";
import EtsPage from "@/components/Layout/EtsStructure";
import EtsHeader from "@/components/Elements/EtsHeader";
import EtsButton from "@/components/Elements/EtsButton";
import EtsButtonGroup from "@/components/Elements/EtsButtonGroup";
import EtsTable from "@/components/Elements/EtsTable";
import EtsAlert from "@/components/Elements/EtsAlert";

export default {
  name: "app",
  components: {
    EtsNavBar,
    EtsSidebar,
    EtsStructure,
    EtsPage,
    EtsButton,
    EtsButtonGroup,
    EtsHeader,
    EtsTable,
    EtsAlert
  },
  computed: {
    tableDataComputed() {
      return [
        {
          text: this.text,
          title: "i",
          img: "25 Apr 2018",
          recoID: 0
        },
        ...this.tableData
      ];
    },
    columns() {
      return [
        {
          text: "AFDSFDS",
          colspan: 1,
          key: "text",
          sup: 3
        },
        {
          text: "BFDSFd",
          colspan: 1,
          key: "title"
        },
        {
          text: "CFDSFD",
          colspan: 1,
          key: "img"
        }
      ];
    },
    sections() {
      return [
        {
          title: "Portfolio Analysis",
          links: [
            {
              name: "Asset Allocation"
            },
            {
              name: "Performance"
            },
            {
              name: "Risk"
            },
            {
              name: "Risk Appetite"
            }
          ]
        }
      ];
    }
  },
  data: () => ({
    showAlert: true,
    text: true,
    tableData: [
      {
        text: "false",
        title: "i",
        img: "25 Apr 2018",
        recoID: 2
      },
      {
        text: "true",
        title: "W",
        img: "-",
        recoID: 3
      },
      {
        text: "false",
        title: "F",
        img: "10 Jun 2018",
        recoID: 4
      }
    ],
    selected: []
  }),
  methods: {
    addRow() {
      this.tableData.push({
        text: "09:10:27",
        title: "w",
        img: "11 Jun 2017",
        recoID: 1
      });
    },
    selectedDataFromChild(selectedData) {
      this.selected = selectedData;
    }
  }
};
</script>

<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
}
.tabs {
  margin-left: 20px;
  margin-top: 50px;
}
.calendar-test ::v-deep {
  .date1 {
    box-shadow: 0 0 0 2px #c44d58;
  }
  .date2 {
    box-shadow: 0 0 0 2px #073ed6;
  }
}
.size {
  height: 200px;
  margin: 0px auto;
  width: 1300px;
  max-width: 100%;
}
</style>
