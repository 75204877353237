<template>
  <div class="v-msg-container">
    <div class="v-msg-container v-msg-top-right">
      <transition-group name="slide-fade-right">
        <div class="v-msg-box" :class="{ [t.theme]: t.theme }" v-for="t in pool['top-right']" :key="t.key">
          <button aria-label="Close" data-dismiss="alert" class="close" type="button" @click="remove(t)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="t.title">{{ t.title }}</h6>
          {{ t.message }}
        </div>
      </transition-group>
    </div>
    <div class="v-msg-container v-msg-top-left">
      <transition-group name="slide-fade-left">
        <div class="v-msg-box" :class="{ [t.theme]: t.theme }" v-for="t in pool['top-left']" :key="t.key">
          <button aria-label="Close" data-dismiss="alert" class="close" type="button" @click="remove(t)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="t.title">{{ t.title }}</h6>
          {{ t.message }}
        </div>
      </transition-group>
    </div>
    <div class="v-msg-container v-msg-bottom-left">
      <transition-group name="slide-fade-left">
        <div class="v-msg-box" :class="{ [t.theme]: t.theme }" v-for="t in pool['bottom-left']" :key="t.key">
          <button aria-label="Close" data-dismiss="alert" class="close" type="button" @click="remove(t)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="t.title">{{ t.title }}</h6>
          {{ t.message }}
        </div>
      </transition-group>
    </div>
    <div class="v-msg-container v-msg-bottom-right">
      <transition-group name="slide-fade-right">
        <div class="v-msg-box" :class="{ [t.theme]: t.theme }" v-for="t in pool['bottom-right']" :key="t.key">
          <button aria-label="Close" data-dismiss="alert" class="close" type="button" @click="remove(t)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="t.title">{{ t.title }}</h6>
          {{ t.message }}
        </div>
      </transition-group>
    </div>
    <div class="v-msg-container v-msg-top-center">
      <transition-group name="slide-fade-left">
        <div class="v-msg-box" :class="{ [t.theme]: t.theme }" v-for="t in pool['top-center']" :key="t.key">
          <button aria-label="Close" data-dismiss="alert" class="close" type="button" @click="remove(t)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="t.title">{{ t.title }}</h6>
          {{ t.message }}
        </div>
      </transition-group>
    </div>
    <div class="v-msg-container v-msg-bottom-center">
      <transition-group name="slide-fade-left">
        <div class="v-msg-box" :class="{ [t.theme]: t.theme }" v-for="t in pool['bottom-center']" :key="t.key">
          <button aria-label="Close" data-dismiss="alert" class="close" type="button" @click="remove(t)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="t.title">{{ t.title }}</h6>
          {{ t.message }}
        </div>
      </transition-group>
    </div>

    <transition name="slide-fade-left" v-if="msg">
      <div class="v-msg-backdrop"></div>
    </transition>

    <transition name="slide-fade-left" v-if="msg">
      <div class="v-msg-container v-msg-alert">
        <div class="v-msg-box">
          <button aria-label="Close" class="close" type="button" @click="close(0)">
            <span aria-hidden="true">&times;</span>
          </button>
          <h6 class="v-msg-title" v-if="msg.title">{{ msg.title }}</h6>
          <p class="v-msg-content">{{ msg.message }}</p>

          <div class="v-msg-bottom">
            <button class="btn btn-primary" @click="close(1)">{{ msg.btn.ok }}</button>
            <button class="btn btn-light float-right" v-if="msg.type == 'confirm'" @click="close(0)">{{ msg.btn.cancel }}</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "EtsToast",
  data() {
    return {
      items: [],
      defaults: {
        title: "Message",
        position: "top-right",
        timeout: 8000,
        btn: {
          ok: " OK ",
          cancel: " Cancel"
        },
        callback: null
      },
      msg: null,
      pool: {
        "top-right": [],
        "top-left": [],
        "top-center": [],
        "bottom-left": [],
        "bottom-right": [],
        "bottom-center": []
      }
    };
  },
  methods: {
    success(message, option = {}) {
      return this.add(message, Object.assign(option, { theme: "v-notify-success" }));
    },
    info(message, option = {}) {
      return this.add(message, Object.assign(option, { theme: "v-notify-info" }));
    },
    warning(message, option = {}) {
      return this.add(message, Object.assign(option, { theme: "v-notify-warning" }));
    },
    error(message, option = {}) {
      return this.add(message, Object.assign(option, { theme: "v-notify-error" }));
    },
    alert(message, option = {}) {
      return this.add(message, Object.assign(option, { type: "alert" }));
    },
    confirm(message, option = {}) {
      return this.add(message, Object.assign(option, { type: "confirm" }));
    },
    add(message, option) {
      return new Promise(resolve => {
        var item = Object.assign({}, this.defaults, option, {
          message,
          key: `${Date.now()}-${Math.random()}`,
          callback: resolve
        });
        if (item.type == "alert" || item.type == "confirm") {
          this.msg = item;
        } else {
          this.pool[item.position].push(item);
          if (item.timeout > 0) setTimeout(() => this.remove(item), item.timeout);
        }
      });
    },
    close(val) {
      if (this.msg.callback) {
        this.msg.callback({ data: val });
      }
      this.msg = null;
    },
    remove(item) {
      let i = this.pool[item.position].indexOf(item);
      if (i >= 0) {
        this.pool[item.position].splice(i, 1);
      }
    },
    mount() {
      this.$mount();
      document.body.appendChild(this.$el);
    }
  }
};
</script>
<style lang="scss" scoped>
$success1: map-get($button_colors, "success1");

.v-msg-container {
  position: fixed;
  z-index: 10000;
  width: 360px;
  padding-left: 10px;
  padding-right: 10px;
}
.v-msg-top-right {
  top: 10px;
  right: 0;
}
.v-msg-top-left {
  top: 10px;
  left: 0;
}
.v-msg-bottom-left {
  bottom: 10px;
  left: 0;
}
.v-msg-bottom-right {
  bottom: 10px;
  right: 0;
}
.v-msg-top-center,
.v-msg-bottom-center,
.v-msg-alert {
  left: 50%;
  margin-left: -180px;
}
.v-msg-top-center {
  top: 10px;
}
.v-msg-bottom-center {
  bottom: 10px;
}
.v-msg-alert {
  top: 20%;
}
.v-msg-box {
  /* margin-bottom: 10px;

  border: 1px solid #454d5d;
  border-radius: 5px;
  color: #fff;
  display: block;
  padding: 1rem;
  background: rgba(69, 77, 93, 0.9);
  border-color: #454d5d; */
  display: block;
  padding: 12px 20px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.215rem;
  transition: all 0.3s ease;
  background: #fff;
  box-shadow: 1px 9px 14px -3px rgba(0, 0, 0, 0.26);
  font-size: 1.5rem;
}
h6.v-msg-title {
  font-weight: bold;
  margin: 0;
  margin-bottom: 5px;
  font-size: 1.9rem;
}
.v-msg-alert .v-msg-title {
  padding-top: 10px;
  padding-bottom: 10px;
}
.v-msg-alert .v-msg-content {
  /* padding-top: 10px; */
  padding-bottom: 10px;
}
.v-msg-alert .btn {
  min-width: 90px;
}
.float-right {
  float: right !important;
}
.v-notify-success {
  color: white;
  background-color: $success1;
}
.v-notify-success .close {
  color: white;
}
.v-notify-success .close:hover,
.v-notify-success .close:focus {
  color: #eee;
}
.v-notify-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.v-notify-info .close {
  color: #0c5460;
}
.v-notify-info .close:hover,
.v-notify-info .close:focus {
  color: #0c5460;
}
.v-notify-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.v-notify-warning .close {
  color: #856404;
}
.v-notify-warning .close:hover,
.v-notify-warning .close:focus {
  color: #856404;
}
.v-notify-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.v-notify-error .close {
  color: #721c24;
}
.v-notify-error .close:hover,
.v-notify-error .close:focus {
  color: #721c24;
}
.v-msg-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1600;
  background-color: #000;
  opacity: 0.5;
}
.close {
  float: right;
  font-size: 2rem;
  font-weight: 700;
  color: black;
  opacity: 0.5;
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.slide-fade-left-leave-active,
.slide-fade-left-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-left-enter,
.slide-fade-left-leave-to {
  transform: translateX(-50px);
  opacity: 0;
}
.slide-fade-right-leave-active,
.slide-fade-right-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-right-enter,
.slide-fade-right-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
@media (max-width: 360px) {
  .v-notify-container {
    width: 100%;
  }
}
</style>

<docs>

```vue
<template>
  <div>
    <ets-button mode="main" @click="showToast" text="Show success toast"></ets-button>
    <ets-button mode="error" @click="showToastError" text="Show error toast"></ets-button>
  </div>
</template>
<script>
export default {
  methods: {
    showToast() {
      this.$EtsToast.success("Sucess example", {
        title: "OK",
        timeout: "4000"
      });
    },
    showToastError() {
      this.$EtsToast.error("Error example", {
        title: "Error",
        timeout: "4000"
      });
    }
  },
  mounted() {
    this.$EtsToast.mount();
  }
};
</script>
```

The toast component is used to display a notification for a certain time. This component is special, it does not need to be imported and inserted into the components object. To use it you have to invoke the method directly:

Example:

```jsx static
this.$EtsToast.success("FTP file published", {
  title: "OK",
  timeout: "4000"
});
```
For the toast animation to be seen correctly it is very important that inside the component in which you are going to use it, inside the hook mounted you call the function of the toast to initialize it and load it in the view so that it doesn't slow down its animation.

```jsx static
mounted() {
  this.$EtsToast.mount();
},
```
The toast type is decided by invoking the corresponding method, in the example above it would be sucess type. The available types are the following:

- success
- info
- warning
- error
- confirm
- alert

The parameters you can configure are the following:

```jsx static
defaults: {
  title: "Message",
  position: "top-right",
  timeout: 8000,
  btn: {
    ok: " OK ",
    cancel: " Cancel"
  },
  callback: null
},
```

If you want to use toast to show axial errors, in an interceptor you have to pass the Vue instance to the interceptor itself. What is recommended is to call in the main.js the mount method of EtsToast so that it is mounted only once.
The interceptor would look like this:

```jsx static
import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("token");

export default function setup(vueInstance) {
  axios.interceptors.request.use(
    function(config) {
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    function(err) {
      vueInstance.$EtsToast.error("Backend error", {
        title: "ERROR",
        timeout: "4000"
      });
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(err) {
      let code = err.response.status;
      let statusText = err.response.statusText;
      vueInstance.$EtsToast.error(statusText, {
        title: "ERROR " + code,
        timeout: "4000"
      });
      return Promise.reject(err);
    }
  );
}
```
In this case pull error in the event that there is error in the response or in the request.

</docs>
