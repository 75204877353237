<template>
  <nav class="ets-menu-icons" role="navigation-tools">
    <ets-link-icon
      v-for="icon in icons"
      :key="icon.id"
      :icon-name="icon.name"
      :icon-color-hover="icon.color"
      :title="icon.title"
      :href="icon.href"
    />
  </nav>
</template>

<script>
import EtsLinkIcon from "@/components/Elements/EtsLinkIcon";
import ConfigStore from "@/configStore";

let store = new ConfigStore();

export default {
  name: "EtsNavBarToolbar",
  components: {
    EtsLinkIcon
  },
  props: {
    baseUrlOld: {
      type: String,
      required: false
    }
  },
  computed: {
    intranetUrl() {
      return "//" + this.baseUrlOld;
    },
    accountUrl() {
      let url = store.get();
      return url.urlAccount;
    },
    icons() {
      return [
        {
          name: "wikipedia",
          color: "grey",
          title: "Wiki",
          href: "//etswiki",
          id: 3
        },
        {
          name: "users_dotnet",
          color: "red",
          title: "RRHH",
          href: this.accountUrl + "/worked-hours",
          id: 4
        },
        {
          name: "phone",
          color: "purple",
          title: "Phone List",
          href: this.intranetUrl + "/User/ETSPhones.aspx",
          id: 5
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-menu-icons {
  align-items: center;
  display: flex;
  margin-right: 1.5rem;
  height: 100%;
  align-items: center;

  ::v-deep .ets-link-icon:hover {
    text-decoration: none;
  }
}
</style>

<docs>
```jsx
<div class="navbar" style="width: 100%; background: #363f48; height: 50px;">
  <ets-nav-bar-toolbar />
</div>
```
</docs>
