<template>
  <input v-if="submit" type="submit" class="ets-btn" :disabled="disabled" :class="computedClass" :value="text" @click="$emit('click')" />
  <router-link v-else-if="to" class="ets-btn" :disabled="disabled" :class="computedClass" :to="to">
    <span class="button-content" :class="{ reversed }">
      <ets-icon v-if="icon" class="icon" :icon="icon" :class="{ inline: this.text }" />
      <span v-if="text" class="text">{{ text }}</span>
    </span>
  </router-link>
  <button v-else class="ets-btn" :disabled="disabled || loading" :class="computedClass" @click="click">
    <span class="button-content" :class="{ reversed }">
      <ets-spinner v-if="loading" :size="28" center />
      <template v-else>
        <ets-icon v-if="icon" class="icon" :icon="icon" :class="{ inline: this.text }" />
        <span v-if="text" class="text">{{ text }}</span>
      </template>
    </span>
  </button>
</template>

<script>
import EtsSpinner from "@/components/Elements/EtsSpinner";
import EtsIcon from "@/components/Elements/EtsIcon";

export default {
  name: "EtsButton",
  components: {
    EtsSpinner,
    EtsIcon
  },
  props: {
    /**
     * Valid sizes: `xs, md, sm, xl`
     */
    size: {
      type: String,
      validator: value => {
        return ["xs", "sm", "md", "xl"].indexOf(value) !== -1;
      }
    },
    /**
     * Valid types: `main, error, border, success`
     */
    mode: {
      type: String,
      default: "",
      validator: value => {
        return ["", "main", "error", "border", "success"].indexOf(value) !== -1;
      }
    },
    /**
     * Text inside the button
     */
    text: {
      type: String,
      default: ""
    },
    /**
     * Icon name inside the button.
     */
    icon: {
      type: String,
      default: ""
    },
    /**
     * To display spinner inside the button
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * To change the button to a input with submit type
     */
    submit: {
      type: Boolean,
      default: false
    },
    /**
     * No event is emitted
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * To change the order between text and icon inside a button
     */
    reversed: {
      type: Boolean,
      default: false
    },
    /**
     * To select or unselect the button
     */
    selected: {
      type: Boolean,
      default: false
    },
    /**
     * Change the button to a link
     */
    to: {
      type: [Object, String],
      default: null
    }
  },
  computed: {
    computedClass() {
      return [
        this.size ? `ets-btn--${this.size}` : "",
        this.mode ? `ets-btn--${this.mode}` : "",
        {
          "ets-btn--square": !this.text,
          "ets-btn--selected": this.selected
        }
      ];
    }
  },
  methods: {
    /** When the user clicks the button
     * @event click
     * @type {object}
     */
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
$default: map-get($button_colors, "default");
$color: map-get($button_colors, "color");
$default_color: map-get($button_colors, "default_color");
$selected: map-get($button_colors, "selected");

$main1: map-get($button_colors, "main1");
$main2: map-get($button_colors, "main2");
$main3: map-get($button_colors, "main3");

$error1: map-get($button_colors, "error1");
$error2: map-get($button_colors, "error2");
$error3: map-get($button_colors, "error3");

$success1: map-get($button_colors, "success1");
$success2: map-get($button_colors, "success2");
$success3: map-get($button_colors, "success3");

$text_size: map-get($button_config, "text_size");
$padding: map-get($button_config, "padding");

.ets-btn {
  display: inline-block;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  color: $default_color;
  min-width: 40px;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  white-space: nowrap;
  vertical-align: middle;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  font-size: $text_size;
  min-height: 3.6rem;
  padding: $padding;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 2px;
  background-color: $default;
  text-transform: none;
  outline: none;
  margin: 0 4px;

  ::v-deep path {
    fill: $default_color;
  }

  /* States */
  &:hover {
    border-color: rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    color: #262626;
    background-color: $color;
    text-decoration: none;

    & ::v-deep path {
      fill: #262626;
    }
  }
  &:active {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.17);
    background-color: $selected;
    cursor: default;
    transition: all 0s;
  }
  &:focus {
    box-shadow: inset 0 0 0 1px $color;
  }
  &[disabled] {
    color: #ddd !important;
    border-color: rgba(0, 0, 0, 0.17);
    opacity: 0.5;
    cursor: not-allowed;
    ::v-deep path {
      fill: #ddd;
    }
    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
      color: #ddd;
      background-color: #fcfcfc;
    }
  }
  &.ets-btn--selected {
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.05);
    background-color: $selected;
    &:hover {
      box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.17);
      background-color: $selected;
      cursor: default;
      transition: all 0s;
    }
  }
  /* Sizes */
  &.ets-btn--xs {
    margin-left: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-height: 1.6rem;
    min-width: 20px;
  }
  &.ets-btn--sm {
    margin-left: 0;
    min-height: 2.2rem;
  }
  &.ets-btn--md {
    margin-left: 0.5rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    min-height: 3.6rem;
    min-width: 30px;
  }
  &.ets-btn--xl {
    margin-left: 0.7rem;
    padding: 0.35rem 1.2rem;
    min-width: 40px;
  }

  &.ets-btn--square {
    padding: 0.5rem;
    .icon {
      margin-right: 0;
    }
  }

  /* Type */
  &.ets-btn--main {
    background: $main1;
    background-image: -webkit-linear-gradient(top, $main1, $main2);
    border-color: $main3;
    color: $color;
    ::v-deep path {
      fill: $color;
    }
    &:hover {
      background: $main3;
      background-image: -webkit-linear-gradient(top, $main1, $main3);
      border-color: rgba(0, 0, 0, 0.17);
      color: $color;
    }
  }
  &.ets-btn--border {
    background: transparent;
    border-color: $main1;
    color: $default_color;
    ::v-deep path {
      fill: $main1;
    }
    &:hover {
      background-color: $default;
      border-color: rgba(0, 0, 0, 0.17);
    }
  }
  &.ets-btn--error {
    background: $error1;
    background-image: -webkit-linear-gradient(top, $error1, $error2);
    border-color: rgba(0, 0, 0, 0.17);
    color: $color;
    ::v-deep path {
      fill: $color;
    }
    &:hover {
      background: $error3;
      background-image: -webkit-linear-gradient(top, $error1, $error3);
      border-color: rgba(0, 0, 0, 0.2);
      color: $color;
    }
  }
  &.ets-btn--success {
    background: $success1;
    background-image: -webkit-linear-gradient(top, $success1, $success2);
    border-color: rgba(0, 0, 0, 0.2);
    color: $color;
    ::v-deep path {
      fill: $color;
    }
    &:hover {
      background: $success3;
      background-image: -webkit-linear-gradient(top, $success1, $success3);
      border-color: rgba(0, 0, 0, 0.2);
      color: $color;
    }
  }
  .ets-btn--main,
  .ets-btn--success,
  .ets-btn--error {
    :before {
      color: $color !important;
    }
  }
}
.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  &.reversed {
    flex-direction: row-reverse;
    .icon {
      margin-right: 0;
      margin-left: 0.8rem;
    }
  }
}
.icon {
  margin-right: 0.8rem;
  &.inline {
    width: 20px;
    height: 20px;
  }
}
</style>

<docs>
```jsx
<div>
  <ets-button text="Default"></ets-button>
  <ets-button mode="main" text="Main"></ets-button>
  <ets-button mode="error" text="Error"></ets-button>
  <ets-button mode="success" text="Success"></ets-button>
  <ets-button mode="border" text="Border"></ets-button>
  <ets-button mode="main" size="xs" text="XS"></ets-button>
  <ets-button mode="main" size="md" text="MD"></ets-button>
  <ets-button mode="main" size="xl" text="XL"></ets-button>
  <ets-button mode="main" text="Disabled" disabled></ets-button>
  <ets-button mode="main" text="Loading" loading></ets-button>
</div>
```
</docs>
