<template>
  <div class="datepicker">
    <ets-button text="prev" :disabled="!disablePrev" @click="prevMonth" />
    {{ currentYear }}
    <ets-button text="next" @click="nextMonth" />
    <ets-calendar-month
      class="datepicker_calendar"
      @date-clicked="dateClicked"
      :year="currentYear"
      :month="currentMonth"
      :clickableFrom="clickableFrom"
      :dates="selectedDates"
    >
    </ets-calendar-month>
  </div>
</template>

<script>
import EtsCalendarMonth from "@/components/Elements/EtsCalendarMonth";
import EtsButton from "@/components/Elements/EtsButton";

export default {
  name: "EtsDatePicker",
  components: {
    EtsCalendarMonth,
    EtsButton
  },
  props: {
    /**
     * Min date the user can click
     */
    clickableFrom: {
      type: Date,
      default: null
    }
  },
  data: () => ({
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    selectedDates: []
  }),
  methods: {
    prevMonth() {
      if (this.disablePrev) {
        if (this.currentMonth > 0) {
          this.currentMonth--;
        } else {
          this.currentMonth = 11;
          this.currentYear--;
        }
      }
    },
    nextMonth() {
      if (this.currentMonth < 11) {
        this.currentMonth++;
      } else {
        this.currentMonth = 0;
        this.currentYear++;
      }
    },
    dateClicked(date) {
      this.selectedDates = [];
      this.selectedDates.push({ date: date, cssClass: "selected_date" });
      /** When the user selects a date
       * @type {object}
       */
      this.$emit("selected-date", date);
    }
  },
  computed: {
    disablePrev() {
      return this.currentMonth > new Date().getMonth() || this.currentYear > new Date().getFullYear();
    }
  }
};
</script>

<style scoped lang="scss">
.datepicker ::v-deep .selected_date {
  background-color: cyan;
}
.datepicker_calendar {
  margin-top: 15px;
}
</style>

<docs>

Under the hood this component uses the **EtsCalendarMonth** component.
```vue
<ets-date-picker />
```
</docs>
