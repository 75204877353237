<template>
  <form ref="fileform" id="file-drag-drop" class="dropzone" :class="{ dragenter: dragenter }">
    <div class="drop-files">
      <span class="title">Drag and drop yor file or...</span>
      <input type="file" name="file" id="file" class="inputfile" @change="buttonClicked" :multiple="multiple" />
      <label for="file"> <ets-icon width="20px" height="20px" icon="search" class="main inputfile-icon" />Choose file </label>
    </div>
    <div v-if="files.length !== 0" class="files-uploaded">
      <ul v-if="multiple">
        <li v-for="(file, i) in files" :key="i">{{ file.name }}</li>
      </ul>
      <span v-else>{{ files[0].name }}</span>
    </div>
    <span v-else class="files-uploaded">{{ filename }}</span>
  </form>
</template>

<script>
import EtsIcon from "@/components/Elements/EtsIcon";
export default {
  name: "EtsFileDropper",
  components: {
    EtsIcon
  },
  props: {
    /**
     * To allow the user to upload multiple files at once
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * Overrides the filename to display
     */
    filename: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    dragAndDropCapable: false,
    files: [],
    dragenter: false
  }),

  mounted() {
    /*
        Determine if drag and drop functionality is capable in the browser
      */
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    /*
        If drag and drop capable, then we continue to bind events to our elements.
      */
    if (this.dragAndDropCapable) {
      /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(
        function (evt) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );
        }.bind(this)
      );
      /*
          Add an event listener for drop to the form
        */
      this.$refs.fileform.addEventListener(
        "dragover",
        function () {
          if (!this.dragenter) {
            this.dragenter = true;
          }
        }.bind(this)
      );
      this.$refs.fileform.addEventListener(
        "dragleave",
        function () {
          this.dragenter = false;
        }.bind(this)
      );

      /*
          Add an event listener for drop to the form
        */
      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
          this.files = [];
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
          }
          this.dragenter = false;
          this.emitChanges();
        }.bind(this)
      );
    }
  },

  methods: {
    buttonClicked(event) {
      this.files = [];
      for (let i = 0; i < event.target.files.length; i++) {
        this.files.push(event.target.files[i]);
      }
      this.emitChanges();
    },
    emitChanges() {
      if (this.multiple) {
        /** When the user drops the file
         * @type {object}
         */
        this.$emit("dropped", this.files);
      } else {
        this.$emit("dropped", this.files[0]);
      }
    },
    /*
        Determines if the drag and drop functionality is in the
        window
      */
    determineDragAndDropCapable() {
      /*
          Create a test element to see if certain events
          are present that let us do drag and drop.
        */
      const div = document.createElement("div");

      /*
          Check to see if the `draggable` event is in the element
          or the `ondragstart` and `ondrop` events are in the element. If
          they are, then we have what we need for dragging and dropping files.

          We also check to see if the window has `FormData` and `FileReader` objects
          present so we can do our AJAX uploading
        */
      return ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) && "FormData" in window && "FileReader" in window;
    }
  }
};
</script>

<style lang="scss" scoped>
.dropzone {
  width: 100%;
  min-height: 150px;
  border: 1px dashed #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background 0.2s;
}
.drop-files {
  display: flex;
  align-items: center;
}
.dragenter {
  background: rgb(241, 241, 241);
}
.title {
  color: #bdbdbd;
  font-size: 1.2rem;
}
.subtitle {
  margin-top: 1.5rem;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  color: #4f4f4f;
  background-color: transparent;
  padding: 0.5rem 3rem;
  border: 1px solid $color-main;
  border-radius: 3px;
  transition: background-color 0.2s;
  cursor: pointer;
  margin-left: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #eee;
}
.drop-files ::v-deep .inputfile-icon {
  margin-right: 1rem;
}
.icon {
  display: flex;
  align-items: center;
}
.files-uploaded {
  margin-top: 2rem;
}
</style>

<docs>
```vue
<ets-file-dropper />
```
</docs>
