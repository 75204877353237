<template>
  <header class="ets-header" :class="navClass">
    <ets-nav-bar-logo :base-url-old="intranetUrl" />
    <ets-nav-bar-menu :base-url-old="intranetUrl" :section="section" />
    <ets-nav-bar-search :base-url-old="intranetUrl" :search-placeholder="searchPlaceholder" @search="search" :label="label" />
    <ets-nav-bar-toolbar :base-url-old="intranetUrl" />
  </header>
</template>

<script>
import EtsNavBarLogo from "@/components/Elements/NavBar/EtsNavBarLogo";
import EtsNavBarMenu from "@/components/Elements/NavBar/EtsNavBarMenu";
import EtsNavBarSearch from "@/components/Elements/NavBar/EtsNavBarSearch";
import EtsNavBarToolbar from "@/components/Elements/NavBar/EtsNavBarToolbar";

import ConfigStore from "@/configStore";

let store = new ConfigStore();

export default {
  name: "EtsNavBar",
  props: {
    /**
     * Search text placeholder for contextual search
     */
    searchPlaceholder: {
      type: String,
      required: false,
      default: "Symbols"
    },
    /**
     * Label of the search input. Null by default
     */
    label: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * The current page section of the navbar. Valid values `products, labs, history data, admin, tech `
     */
    section: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    EtsNavBarLogo,
    EtsNavBarMenu,
    EtsNavBarSearch,
    EtsNavBarToolbar
  },
  computed: {
    intranetUrl() {
      let url = store.get();
      if (url.urlIntranet) {
        return url.urlIntranet.replace(/^(?:http?:\/\/)?(?:www\.)?(?:\/\/)?/i, "").split("/")[0];
      } else {
        return "";
      }
    },
    navClass() {
      return "nav-" + this.section;
    }
  },
  methods: {
    search(searchText) {
      this.$emit("search", searchText);
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-header {
  align-items: center;
  background-color: $color-header;
  border-bottom: 0.4rem solid #ccc;
  display: flex;
  height: 7.4rem;
  justify-content: space-between;
  z-index: 2;
}

@each $color, $value in $menu-colors {
  .nav-#{$color} {
    border-bottom: 4px solid $value;
  }
}
</style>

<docs>

**NOTE**: This component has width for correct display in the docs. You don't have to use this width hack.
This component uses all the navbar components under the hood.

```jsx
<ets-nav-bar style="width: 180%" />
```
</docs>
