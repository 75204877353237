<template>
  <div>
    <section class="ets-calendar-wrapper">
      <div class="month-title">
        <span>{{ monthName }}</span>
        <span class="year">{{ year }}</span>
      </div>
      <div class="calendar">
        <div class="cell-wr" v-for="(wd, i) in dayNames" :key="i">
          <span class="cell dayname">{{ wd }}</span>
        </div>
        <div class="cell-wr" v-for="d in prevMonthDates" :key="`${d}-prev`" :class="{ noselectable: !isRangeDates }">
          <span class="cell" :class="[computedStylePrevNext(d, -1)]">
            {{ d }}
          </span>
        </div>
        <div class="cell-wr" v-for="d in monthDates" :key="`${d}-current`">
          <template v-if="isSpecialDate(d)">
            <router-link
              v-if="hasRoute(d)"
              :to="hasRoute(d)"
              class="cell"
              :class="[{ today: isToday(d), selectable: isSelectable(d) }, computedStyle(d)]"
            >
              {{ d }}
            </router-link>
            <span
              v-else
              class="cell"
              :class="[{ today: isToday(d), selectable: isSelectable(d) }, computedStyle(d)]"
              @click="dateClicked(d, $event)"
            >
              {{ d }}
            </span>
          </template>
          <span
            v-else
            class="cell"
            :class="{
              today: isToday(d),
              weekend: isWeekend(d),
              selectable: isSelectable(d)
            }"
            @click="dateClicked(d, $event)"
          >
            {{ d }}
          </span>
          <span v-if="hasBadge(d)" class="badge-number">{{ hasBadge(d) }}</span>
        </div>
        <div class="cell-wr" v-for="d in nextMonthDates" :key="`${d}-next`" :class="{ noselectable: !isRangeDates }">
          <span class="cell" :class="[computedStylePrevNext(d, 1)]">
            {{ d }}
          </span>
        </div>
        <transition name="bounce">
          <div class="popup" v-if="dateSelected" :style="{ left: popupX + 'px', top: popupY + 'px' }" @click.stop>
            <div class="popup-close" @click="closePopup">x</div>
            <div class="badge-flex" v-for="(badge, i) in hasBadges(dateSelected)" :key="i">
              <div class="badge-big" :style="{ background: badge.color }"></div>
              <div class="badge-name">
                <router-link v-if="badge.link" :to="badge.link" class="link">
                  {{ badge.text }}
                </router-link>
                <span v-else>{{ badge.text }}</span>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="legend" class="legend">
        <div class="legend-item" v-for="(item, i) in legend" :key="i">
          <div
            class="legend-circle"
            :style="{
              background: item.color,
              border: '2px solid ' + item.border
            }"
          ></div>
          {{ item.name }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import dayjs from "dayjs";
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export default {
  name: "EtsCalendarMonth",
  props: {
    /**
     * Year to print. By default current year
     */
    year: {
      type: Number,
      default: new Date().getFullYear(),
      validator: value => /^\d{4}$/.test(value)
    },
    /**
     * Month to print. By default current month
     */
    month: {
      type: Number,
      default: new Date().getMonth(),
      validator: value => value >= 0 && value < 12
    },
    /**
     * Array of dates with events
     */
    dates: {
      type: Array,
      default: () => []
    },
    /**
     * Min date the user can click
     */
    clickableFrom: {
      type: Date,
      default: null
    },
    /**
     * Initial date to start print (for only weeks)
     */
    initialDay: {
      type: Date,
      default: null
    },
    /**
     * Final date to start print (for only weeks)
     */
    finalDay: {
      type: Date,
      default: null
    },
    /**
     * The config of the legend
     */
    legend: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dayNames: ["m", "t", "w", "t", "f", "s", "s"],
    popupX: 0,
    popupY: 0,
    dateSelected: null,
    prevRange: [],
    nextRange: []
  }),
  computed: {
    isRangeDates() {
      return this.initialDay !== null && this.finalDay !== null;
    },
    firstDate() {
      return this.isRangeDates ? this.initialDay : new Date(this.year, this.month, 1);
    },
    lastDate() {
      return this.isRangeDates ? this.finalDay : new Date(this.year, this.month + 1, 0);
    },
    monthDates() {
      if (this.isRangeDates) {
        const rangeDays = this.getDateArray(this.initialDay, this.finalDay);
        const emptyPrev = rangeDays[0] < Number(dayjs().format("D"));
        this.getPrevRange(emptyPrev, rangeDays);

        if (rangeDays.indexOf(Number(dayjs().daysInMonth())) !== -1) {
          this.getNextRange(rangeDays);
        }
        return rangeDays;
      } else {
        return this.lastDate.getDate();
      }
    },
    today() {
      const today = new Date();
      return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    },
    weeks() {
      const weeks = [];
      const numDays = this.monthDates;
      let start = 1;
      let end = 7 - this.weekDay(this.firstDate);
      while (start <= numDays) {
        weeks.push({ start, end });
        start = end + 1;
        end = end + 7;
        end = start === 0 && end === 7 ? 1 : end;
        if (end > numDays) {
          end = numDays;
        }
      }
      return weeks;
    },
    monthName() {
      return months[this.month];
    },
    prevMonthDates() {
      if (this.isRangeDates) {
        return this.prevRange;
      } else {
        const numDays = this.weekDay(this.firstDate) % 7;
        const dates = [];
        for (let i = 0; i < numDays; i++) {
          const date = new Date(this.year, this.month, -i);
          dates.unshift(date.getDate());
        }
        return dates;
      }
    },
    nextMonthDates() {
      if (!this.isRangeDates) {
        let days = 7 - (this.weekDay(this.lastDate) + 1);
        if (this.weeks.length === 4) days += 7 * 2;
        if (this.weeks.length === 5) days += 7;
        return days;
      } else {
        let days = this.nextRange;
        return days;
      }
    }
  },
  methods: {
    getPrevRange(emptyPrev, rangeDays) {
      this.prevRange = emptyPrev ? [] : rangeDays.splice(0, rangeDays.indexOf(1));
    },
    getNextRange(rangeDays) {
      this.nextRange = rangeDays.splice(rangeDays.indexOf(Number(dayjs().daysInMonth())) + 1);
    },
    getDateArray(start, end) {
      let dates = [];
      const theDate = new Date(start);
      while (theDate < end) {
        dates.push(new Date(theDate).getDate());
        theDate.setDate(theDate.getDate() + 1);
      }
      while (dates.length % 7 !== 0) {
        // Pinta semanas completas
        dates.pop();
      }
      return dates;
    },
    isToday(date) {
      const checkedDay = new Date(this.year, this.month, date);
      return this.today.getTime() === checkedDay.getTime();
    },
    isWeekend(date) {
      const checkedDay = new Date(this.year, this.month, date);
      if (!this.isRangeDates) {
        return checkedDay.getDay() === 6 || checkedDay.getDay() === 0;
      }
    },
    weekDay(date) {
      return date.getDay() === 0 ? 6 : date.getDay() - 1;
    },
    foundDate(date) {
      const checkedDay = new Date(this.year, this.month, date);
      return this.dates.find(x => x.date.getTime() === checkedDay.getTime());
    },
    foundDatesPrevNext(date, inc) {
      const checkedDay = new Date(this.year, this.month + inc, date);
      return this.dates.find(x => x.date.getTime() === checkedDay.getTime());
    },
    computedStyle(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.cssClass;
    },
    computedStylePrevNext(date, inc) {
      const foundDate = this.foundDatesPrevNext(date, inc);
      if (this.isRangeDates && foundDate) {
        return foundDate.cssClass;
      }
    },
    isSpecialDate(date) {
      return this.foundDate(date);
    },
    hasRoute(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.route;
    },
    hasBadge(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.badge;
    },
    hasBadges(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.badges;
    },
    isSelectable(date) {
      const checkedDay = new Date(this.year, this.month, date);
      return this.clickableFrom
        ? (this.clickableFrom.getTime() < checkedDay.getTime() || this.datesEqual(this.clickableFrom, checkedDay)) && !this.isWeekend(date)
        : true;
    },
    dateClicked(date, e) {
      if (this.isSelectable(date)) {
        const foundDate = this.foundDate(date);
        /**
         * When the user clicks a date
         * @type {object}
         */
        this.$emit("date-clicked", new Date(this.year, this.month, date));
        if (foundDate && foundDate.badges) {
          e.stopPropagation();
          this.dateSelected = date;
          let viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          let posX = e.clientX;
          let posY = e.clientY;

          this.popupX = posX + 5;
          this.popupY = posY + 5;

          if (posX + 380 > viewWidth) {
            this.popupX -= 360;
          }
        }
      }
    },
    datesEqual(date1, date2) {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() == date2.getDate();
    },
    closePopup() {
      this.dateSelected = null;
    }
    // badgesStyles(date) {
    //   const badgeSize = 2;
    //   let styles = "";
    //   const badges = this.hasBadges(date);
    //   if (badges) {
    //     for (let i = 0; i < badges.length; i++) {
    //       const badge = badges[i];
    //       const badgePos = badgeSize * i;
    //       if (i > 1) {
    //         styles += `, 0 0 0 ${badgeSize + 2}px white`;
    //         styles += `, 0 0 0 ${badgePos + 4}px ${badge.color}`;
    //       } else {
    //         styles +=
    //           i === 0
    //             ? `0 0 0 ${badgeSize}px ${badge.color}`
    //             : `, inset 0 0 0 ${badgeSize}px ${badge.color}`;
    //       }
    //     }
    //     return { boxShadow: styles };
    //   }
    // }
  },
  watch: {
    dateSelected(val) {
      val ? document.addEventListener("click", this.closePopup) : document.removeEventListener("click", this.closePopup);
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closePopup);
  },
  created() {
    if (this.isRangeDates) {
      this.monthDates;
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-calendar-wrapper {
  max-width: 250px;
}
.month-title {
  font-size: 1.6rem;
  color: #757575;
  margin-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 1.4rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.year {
  font-size: 1.3rem;
  color: #9e9e9e;
  margin-top: 0.3rem;
}
.calendar {
  display: flex;
  flex-wrap: wrap;
  border-collapse: separate;
  border-spacing: 3px;
  > .cell-wr {
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% / 7);
  }
}
.cell-wr {
  margin: 2px auto;
  position: relative;
}
.cell {
  display: table-cell;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #fff;
}
.dayname {
  color: #757575;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.noselectable {
  .cell {
    color: #e5e5e5;
  }
}
.selectable {
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.weekend {
  color: #a8a7a7;
}
.today {
  background-color: $background-today;
  color: #fff !important;
}
.badge-number {
  position: absolute;
  right: -3px;
  bottom: -7px;
  font-size: 9px;
  border-radius: 50%;
  background-color: #fbb117;
  font-weight: bold;
  color: white;
  padding: 2px 6px;
}
.badges {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: -2px;
  width: 26px;
}
.badge {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.popup {
  min-width: 200px;
  min-height: 100px;
  position: absolute;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
  border-radius: 8px;
  padding: 1rem 2rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup-close {
  position: absolute;
  top: 5px;
  right: 12px;
  cursor: pointer;
  font-size: 24px;
}
.transparent-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.badge-big {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.badge-flex {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.badge-name {
  margin-left: 10px;
}
.badge-title {
  margin: 5px 0;
  margin-top: 18px;
}
.bounce-enter-active {
  animation: bounce-in 0.2s;
}
.bounce-leave-active {
  animation: bounce-in 0.1s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.legend-item {
  margin: 0.5rem 1rem;
  display: flex;
}
.legend-circle {
  margin-top: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 0.6rem;
}
</style>

<docs>
```vue
  <ets-calendar-month :year="2012" :month="5" />
```

Current month example:
```vue
  <ets-calendar-month />
```
</docs>
