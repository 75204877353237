<template>
  <div>
    <p class="message">
      <slot>There is no data</slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "EtsNoDataMsg"
};
</script>

<style lang="scss" scoped>
.message {
  font-size: 3rem;
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: bold;
  color: #999;
  text-transform: uppercase;
}
</style>

<docs>
```jsx
<ets-no-data-msg />
```
</docs>
