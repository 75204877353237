<template>
  <section v-if="isActive" :aria-hidden="!isActive" class="tabs-component-panel" :id="hash" role="tabpanel">
    <slot />
  </section>
</template>

<script>
export default {
  name: "EtsTabItem",
  props: {
    /**
     * ID used for the tab. If no id is passed the component will be used the name
     */
    id: {
      type: String,
      default: null,
      required: false
    },
    /**
     * Name of the tab to display
     */
    name: {
      type: String,
      default: "",
      required: true
    },
    /**
     * To disable the tab
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isActive: false,
    isVisible: true
  }),
  computed: {
    hash() {
      if (this.disabled) return "#";
      if (this.id) return "#" + this.id;
      if (this.name) return "#" + this.name.toLowerCase().replace(/ /g, "-");
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-component-panel {
  margin-top: 2rem;
}
</style>

<docs>
This component is used with the EtsTabs components to make tabs. With this component you define the
tabs and his name. The content of this component will be the component of the tabs

```jsx
<ets-tabs>
  <ets-tab-item id="tab1" name="tab1">
    Content of the Tab1
  </ets-tab-item>
  <ets-tab-item id="tab2" name="tab2">
    Content of the Tab2
  </ets-tab-item>
</ets-tabs>
```
</docs>
