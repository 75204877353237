import roundTo from "./EtsRoundTo";

export default function percentage(value = 0, { decimals = 2, showDecimals = true, showPercentage = true } = {}) {
  if ((value && value !== "-") || value === 0) {
    value = value * 100;
    value = roundTo(value, decimals);
    if (showDecimals) value = value.toFixed(decimals);
    if (showPercentage) value = `${value} %`;
  }
  return value;
}
