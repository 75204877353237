// Elements
import EtsAlert from "@/components/Elements/EtsAlert.vue";
import EtsButton from "@/components/Elements/EtsButton.vue";
import EtsButtonGroup from "@/components/Elements/EtsButtonGroup.vue";
import EtsCalendarAnnual from "@/components/Elements/EtsCalendarAnnual.vue";
import EtsCalendarMonth from "@/components/Elements/EtsCalendarMonth.vue";
import EtsCheckboxRadio from "@/components/Elements/EtsCheckboxRadio.vue";
import EtsDatePicker from "@/components/Elements/EtsDatePicker.vue";
import EtsDayPicker from "@/components/Elements/EtsDayPicker.vue";
import EtsFileDropper from "@/components/Elements/EtsFileDropper.vue";
import EtsFileUploader from "@/components/Elements/EtsFileUploader.vue";
import EtsForm from "@/components/Elements/EtsForm.vue";
import EtsHeader from "@/components/Elements/EtsHeader.vue";
import EtsHighcharts from "@/components/Elements/EtsHighcharts.vue";
import EtsIcon from "@/components/Elements/EtsIcon.vue";
import EtsInput from "@/components/Elements/EtsInput.vue";
import EtsInputNumber from "@/components/Elements/EtsInputNumber.vue";
import EtsJsonTree from "@/components/Elements/EtsJsonTree.vue";
import EtsModule from "@/components/Elements/EtsModule.vue";
import EtsMultiSelect from "@/components/Elements/EtsMultiSelect.vue";
import EtsNavBar from "@/components/Elements/NavBar/EtsNavBar";
import EtsNoDataMsg from "@/components/Elements/EtsNoDataMsg.vue";
import EtsPageError from "@/components/Elements/EtsPageError.vue";
import EtsPopup from "@/components/Elements/EtsPopup.vue";
import EtsSidebar from "@/components/Elements/EtsSidebar.vue";
import EtsSpinner from "@/components/Elements/EtsSpinner.vue";
import EtsStars from "@/components/Elements/EtsStars.vue";
import EtsStatus from "@/components/Elements/EtsStatus.vue";
import EtsTabItem from "@/components/Elements/EtsTabItem.vue";
import EtsTable from "@/components/Elements/EtsTable.vue";
import EtsTabs from "@/components/Elements/EtsTabs.vue";
import EtsToggle from "@/components/Elements/EtsToggle.vue";
import EtsDropdown from "@/components/Elements/EtsDropdown.vue";
import EtsWorkingDaysList from "@/components/Elements/EtsWorkingDaysList.vue";
import EtsInputValidation from "@/components/Elements/EtsInputValidation.vue";

// Layout
import EtsFragment from "@/components/Layout/EtsFragment.vue";
import EtsPage from "@/components/Layout/EtsPage.vue";
import EtsStructure from "@/components/Layout/EtsStructure.vue";

const Components = {
  EtsAlert,
  EtsButton,
  EtsButtonGroup,
  EtsCalendarAnnual,
  EtsCalendarMonth,
  EtsCheckboxRadio,
  EtsDatePicker,
  EtsDayPicker,
  EtsFileDropper,
  EtsFileUploader,
  EtsForm,
  EtsFragment,
  EtsHeader,
  EtsHighcharts,
  EtsIcon,
  EtsInput,
  EtsInputNumber,
  EtsJsonTree,
  EtsModule,
  EtsMultiSelect,
  EtsNavBar,
  EtsNoDataMsg,
  EtsPage,
  EtsPageError,
  EtsPopup,
  EtsSidebar,
  EtsSpinner,
  EtsStars,
  EtsStatus,
  EtsStructure,
  EtsTabItem,
  EtsTable,
  EtsTabs,
  EtsToggle,
  EtsDropdown,
  EtsWorkingDaysList,
  EtsInputValidation
};

export default Components;
