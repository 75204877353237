<template>
  <section class="wrapper">
    <div class="daypicker">
      <div class="title">
        <span>
          <span v-for="(m, i) in getMonthName()" :key="`${i}M`">
            {{ i === 0 ? m : `/ ${m}` }}
          </span>
        </span>
        <span>
          <span class="year" v-for="(y, i) in getYear()" :key="`${i}Y`">
            {{ i === 0 ? y : `- ${y}` }}
          </span>
        </span>
      </div>
      <div class="days-container">
        <div class="cell-wr" v-for="(d, i) in datesArray" :key="`${i}W`">
          <router-link
            v-if="hasRoute(d)"
            :to="hasRoute(d)"
            class="cell"
            :style="computedStyleColor(d)"
            :class="[{ today: isToday(d), selectable: isSelectable(d) }, computedStyle(d)]"
          >
            <span class="dayname">{{ getDayName(d) }}</span>
            <span>{{ d.date() }}</span>
          </router-link>
          <span
            v-else
            class="cell"
            :style="computedStyleColor(d)"
            :class="[{ today: isToday(d), selectable: isSelectable(d) }, computedStyle(d)]"
            @click="dateClicked(d, $event)"
          >
            <span class="dayname">{{ getDayName(d) }}</span>
            <span>{{ d.date() }}</span>
          </span>
        </div>
      </div>
      <transition name="bounce">
        <div class="popup" v-if="dateSelected" :style="{ left: popupX + 'px', top: popupY + 'px' }" @click.stop>
          <div class="popup-close" @click="closePopup">x</div>
          <div class="badge-flex" v-for="(badge, i) in hasBadges(dateSelected)" :key="i">
            <div class="badge-big" :style="{ background: badge.color }"></div>
            <div class="badge-name">
              <router-link v-if="badge.link" :to="badge.link" class="link">
                {{ badge.text }}
              </router-link>
              <span v-else>{{ badge.text }}</span>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="legend" class="legend">
      <div class="legend-item" v-for="(item, i) in legend" :key="i">
        <div
          class="legend-circle"
          :style="{
            background: item.color,
            border: '2px solid ' + item.border
          }"
        ></div>
        {{ item.name }}
      </div>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "EtsWorkingDaysList",
  data: () => ({
    dayNames: ["M", "T", "W", "T", "F"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    datesArray: [],
    dateSelected: null
  }),
  props: {
    /**
     * Array of dates with events
     */
    dates: {
      type: Array,
      default: () => []
    },
    /**
     * Min date the user can click
     */
    clickableFrom: {
      type: Date,
      default: null
    },
    /**
     * Number of working days to paint
     */
    numDays: {
      type: Number,
      required: true
    },
    /**
     * Last working day to paint
     */
    lastDay: {
      type: Object,
      required: true
    },
    /**
     * The config of the legend
     */
    legend: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isToday(date) {
      return dayjs().startOf("day").unix() === date.startOf("day").unix();
    },
    foundDate(date) {
      return this.dates.find(x => x.date.startOf("day").unix() === date.startOf("day").unix());
    },
    computedStyle(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.cssClass;
    },
    computedStyleColor(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) {
        if (Array.isArray(foundDate.color) && foundDate.color.length > 1) {
          return {
            border: "2px solid white",
            "box-shadow": `0 0 0 2px ${foundDate.color[0]}, inset 0 0 0 2px ${foundDate.color[1]}`
          };
        } else {
          return { border: `2px solid ${foundDate.color}` };
        }
      }
    },
    hasRoute(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.route;
    },
    hasBadges(date) {
      const foundDate = this.foundDate(date);
      if (foundDate) return foundDate.badges;
    },
    isSelectable(date) {
      return this.clickableFrom
        ? (this.clickableFrom.startOf("day").unix() < date.startOf("day").unix() || this.datesEqual(this.clickableFrom, date)) &&
            !this.isWeekend(date)
        : true;
    },
    dateClicked(date, e) {
      if (this.isSelectable(date)) {
        const foundDate = this.foundDate(date);
        /**
         * When the user clicks a date
         * @type {object}
         */
        this.$emit("date-clicked", new Date(this.year, this.month, date));
        if (foundDate && foundDate.badges) {
          e.stopPropagation();
          this.dateSelected = date;
          let viewWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          let posX = e.clientX;
          let posY = e.clientY;

          this.popupX = posX + 5;
          this.popupY = posY + 5;

          if (posX + 380 > viewWidth) {
            this.popupX -= 360;
          }
        }
      }
    },
    isWeekend(date) {
      return date.day() === 0 || date.day() === 6;
    },
    getDayName(date) {
      return this.dayNames[date.day() - 1];
    },
    getMonthName() {
      const month = this.datesArray.map(date => this.monthNames[date.month()]);
      return [...new Set(month)];
    },
    getYear() {
      const year = this.datesArray.map(date => date.year());
      return [...new Set(year)];
    },
    getDateArray(start, end) {
      let dates = [];
      let theDate = dayjs(end);
      while (dates.length < this.numDays) {
        if (!this.isWeekend(theDate)) {
          dates.push(theDate);
        }
        theDate = theDate.subtract(1, "days");
      }
      return dates.reverse();
    },
    datesEqual(date1, date2) {
      return date1.startOf("day").unix() === date2.startOf("day").unix();
    },
    closePopup() {
      this.dateSelected = null;
    }
  },
  computed: {
    initialDay() {
      return dayjs(this.lastDay.startOf("day")).subtract(this.numDays + 3, "days");
    },
    finalDay() {
      return dayjs(this.lastDay.startOf("day"));
    }
  },
  watch: {
    dateSelected(val) {
      val ? document.addEventListener("click", this.closePopup) : document.removeEventListener("click", this.closePopup);
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closePopup);
  },
  created() {
    this.datesArray = this.getDateArray(this.initialDay, this.finalDay);
  }
};
</script>

<style scoped lang="scss">
.daypicker {
  border-spacing: 2px;
}
.cell {
  display: table-cell;
  width: 35px;
  height: 35px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.selected {
  background-color: #dadada;
  &:hover {
    background-color: #dadada;
  }
}
.title {
  font-size: 1.6rem;
  color: #757575;
  margin-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 1.4rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.year {
  font-size: 1.3rem;
  color: #9e9e9e;
  margin-top: 0.3rem;
}
.badge-number {
  position: absolute;
  right: -3px;
  bottom: -7px;
  font-size: 9px;
  border-radius: 50%;
  background-color: #fbb117;
  font-weight: bold;
  color: white;
  padding: 2px 6px;
}
.badges {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: -2px;
  width: 26px;
}
.badge {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.popup {
  min-width: 200px;
  min-height: 100px;
  position: absolute;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302), 0 4px 8px 3px rgba(60, 64, 67, 0.149);
  border-radius: 8px;
  padding: 1rem 2rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup-close {
  position: absolute;
  top: 5px;
  right: 12px;
  cursor: pointer;
  font-size: 24px;
}
.badge-big {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.badge-flex {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.badge-name {
  margin-left: 10px;
}
.badge-title {
  margin: 5px 0;
  margin-top: 18px;
}
.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}
.legend-item {
  margin: 0.5rem 1rem;
  display: flex;
}
.legend-circle {
  margin-top: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 0.6rem;
}
.days-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.today {
  background-color: $background-today;
  color: #fff !important;
}
.wrapper {
  max-width: 250px;
  padding: 1rem 0;
}
</style>

<docs>
```vue
  <ets-working-days-list
    :numDays="6"
    :lastDay="lastday"
    :legend="legend"
    :dates="datesPrice"
  />
```
</docs>
