<template>
  <form @submit.prevent="submitForm()" :style="{ width: size }">
    <div class="form-group" v-for="(field, index) in fields" :key="index">
      <label :for="field.name" class="label" v-text="field.label" v-if="field.type !== 'checkbox'"></label>
      <textarea
        v-if="field.type === 'textarea'"
        v-model="fieldData[field.name]"
        :name="field.name"
        class="field"
        :rows="field.rows ? field.rows : 10"
        :cols="field.cols ? field.cols : 30"
        :id="field.name"
        :placeholder="field.default"
        :required="field.required ? field.required : false"
      >
      </textarea>
      <input
        v-else-if="field.type === 'checkbox'"
        v-model="fieldData[field.name]"
        type="checkbox"
        :name="field.name"
        :label="field.name"
        :value="field.value"
        class="checkbox"
      />
      <label :for="field.name" v-text="field.label" v-if="field.type === 'checkbox'" class="checkbox-label"></label>
      <select
        v-else-if="field.type === 'select' && field.options"
        v-model="fieldData[field.name]"
        :name="field.name"
        class="field"
        :id="field.name"
        :required="field.required ? field.required : false"
      >
        <option v-for="(option, index) in field.options" :key="index">{{ option }}</option>
      </select>
      <input
        v-else-if="fieldIsInput(field)"
        v-model="fieldData[field.name]"
        :placeholder="field.default"
        :type="field.type"
        class="field"
        :name="field.name"
        :id="field.name"
        :required="field.required ? field.required : false"
      />
      <ets-button
        v-else-if="field.type === 'button'"
        :submit="true"
        :name="field.name"
        :mode="field.mode"
        :size="field.size"
        class="btn btn-primary"
      >
        {{ field.text }}
      </ets-button>
    </div>
  </form>
</template>
<script>
import EtsButton from "@/components/Elements/EtsButton";

export default {
  name: "EtsForm",
  components: {
    EtsButton
  },
  data() {
    return {
      newBody: "",
      fieldData: [],
      checkboxData: []
    };
  },
  props: {
    fields: {
      type: Array,
      default: () => null
    },
    size: {
      type: String,
      default: "300px"
    }
  },
  created() {
    this.initializeDefaults();
  },
  methods: {
    initializeDefaults() {
      this.fields.forEach(field => {
        if (field.type === "select") {
          if (field.default) {
            this.fieldData[field.name] = field.default;
          }
        }
      });
    },
    submitForm() {
      this.$emit("submit-form", this.fieldData);
    },
    fieldIsInput(field) {
      return field.type === "password" || field.type === "text" || field.type === "email";
    }
  }
};
</script>
<style lang="scss" scoped>
.label {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
}

.field {
  width: 100%;
}

.checkbox {
  margin-top: 15px;
}

.checkbox-label {
  margin-top: 15px;
}
</style>

<docs>
**THIS COMPONENT MUST TO BE REFAZTORIZED TO EMIT ALL EVENTS**
</docs>
