<template>
  <div class="tabs-component">
    <div role="tablist" class="tabs">
      <div
        class="tab"
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
        role="tab"
        v-show="tab.isVisible"
        v-html="tab.name"
        :aria-controls="tab.hash"
        :aria-selected="tab.isActive"
        @click="selectTab(tab.hash)"
        :href="tab.hash"
      ></div>
    </div>
    <div class="tabs-panels">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "EtsTabs",
  props: {
    /**
     * To select a default tab or jump to a tab
     */
    activeTab: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    tabs: [],
    activeTabHash: ""
  }),

  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.setActiveTab();
  },
  methods: {
    findTab(hash) {
      return this.tabs.find(tab => tab.hash === hash);
    },
    selectTab(selectedTabHash) {
      const selectedTab = this.findTab(selectedTabHash);
      if (!selectedTab) {
        return;
      }
      if (selectedTab.isDisabled) {
        return;
      }
      this.tabs.forEach(tab => {
        tab.isActive = tab.hash === selectedTab.hash;
      });
      /**
       * When the user clicks in a tab
       */
      this.$emit("changed", { tab: selectedTab });
      this.activeTabHash = selectedTab.hash;
    },
    setTabVisible(hash, visible) {
      const tab = this.findTab(hash);
      if (!tab) {
        return;
      }
      tab.isVisible = visible;
      if (tab.isActive) {
        // If tab is active, set a different one as active.
        tab.isActive = visible;
        this.tabs.every(tab => {
          if (tab.isVisible) {
            tab.isActive = true;
            return false;
          }
          return true;
        });
      }
    },
    setActiveTab() {
      if (this.activeTab >= 0 && this.activeTab < this.tabs.length) {
        this.selectTab(this.tabs[this.activeTab].hash);
      } else {
        this.selectTab(this.tabs[0].hash);
      }
    }
  },
  watch: {
    activeTab() {
      this.setActiveTab();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  min-width: 100%;
  flex-flow: row wrap;
}
.tab {
  min-width: 80px;
  text-align: center;
  cursor: pointer;
  border-bottom: 2px solid lightgray;
  padding: 1rem 3rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 1.1rem;
}
.is-active {
  border-bottom: 2px solid $color-blue;
  color: $color-blue;
  font-weight: bold;
}
.tabs-panels {
  margin-top: 1rem;
  width: 100%;
}
</style>

<docs>
This component has to be used with ets-tab-item inside to work

```jsx
<ets-tabs>
  <ets-tab-item id="tab1" name="tab1">
    Content of the Tab1
  </ets-tab-item>
  <ets-tab-item id="tab2" name="tab2">
    Content of the Tab2
  </ets-tab-item>
</ets-tabs>
```
</docs>
