<template>
  <main ref="page" class="ets-page" :style="{ height: `${height}px` }">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "EtsPage",
  data: () => ({
    height: 0
  }),
  methods: {
    resize() {
      this.height = window.innerHeight - this.$refs.page.getBoundingClientRect().top;
    }
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  }
};
</script>

<style lang="scss" scoped>
.ets-page {
  padding: 2.3rem;
  overflow: auto;
}
</style>
