<template>
  <aside ref="sidebar" class="ets-sidebar" :style="{ height: `${height}px` }">
    <section v-for="(section, id) in sections" :key="id" class="section">
      <h3 class="title">{{ section.title }}</h3>
      <ul class="links">
        <li v-for="(link, id) in section.links" :key="id">
          <p v-if="link.isDisabled" class="text disabled">{{ link.name }}</p>
          <router-link v-else-if="link.isRouterLink" :to="link.url || link.params" class="text link">{{ link.name }}</router-link>
          <a v-else :href="link.url" class="text link">{{ link.name }}</a>
        </li>
      </ul>
    </section>
  </aside>
</template>

<script>
export default {
  name: "EtsSidebar",
  data: () => ({
    height: 0
  }),
  props: {
    /**
     * The sections of the sidebar
     */
    sections: {
      type: Array,
      required: true
    }
  },
  methods: {
    resize() {
      this.height = window.innerHeight - this.$refs.sidebar.getBoundingClientRect().top;
    }
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  }
};
</script>

<style lang="scss" scoped>
.ets-sidebar {
  box-shadow: inset -5px 0 15px -10px #000;
  min-width: 250px;
  padding-bottom: 5rem;
  overflow-y: auto;
  overflow-x: hidden;

  background: linear-gradient(whitesmoke 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), whitesmoke 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: whitesmoke;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
.section {
  padding-top: 1.5rem;
}
.title {
  color: $color-grey;
  font-size: 1.9rem;
  font-weight: 700;
  font-family: $font-title;
  padding: 0.19rem 1.5rem;
  margin: 0;
  line-height: 3.4rem;
}
.links {
  padding: 0;
  list-style: none;
  margin: 0;
}
.text {
  color: $color-grey;
  font-size: 1.3rem;
  line-height: 2.34rem;
  padding: 0.1em 1.5rem;
}
.link {
  display: block;
  &:hover {
    background-color: rgba(54, 63, 72, 0.1);
    text-decoration: none;
  }
  &.router-link-active {
    background: #363f48;
    color: #fff;
  }
}
.disabled {
  color: $color-grey4;
  margin: 0;
}
</style>
<docs>
```jsx
const sections = [
  {
  title: "Portfolio Analysis",
  links: [
    {
      name: "Asset Allocation"
    },
    {
      name: "Performance"
    },
    {
      name: "Risk"
    },
    {
      name: "Risk Appetite"
    }
    ]
  }
]
<ets-sidebar :sections="sections" style="width: 50%;"/>
```
</docs>
