// Filtros
import EtsCurrency from "@/utils/EtsCurrency";
import { time, date, dateTime, dateAgo } from "@/utils/EtsDate";
import { capitalize } from "@/utils/EtsString";
import EtsPercentage from "@/utils/EtsPercentage";
import EtsEmpty from "@/utils/EtsEmpty";
import EtsNumber from "@/utils/EtsNumber";

const Filters = [
  { name: "etscurrency", filter: EtsCurrency },
  { name: "etsdate", filter: date },
  { name: "etstime", filter: time },
  { name: "etsdateTime", filter: dateTime },
  { name: "etsdateAgo", filter: dateAgo },
  { name: "etspercentage", filter: EtsPercentage },
  { name: "etsempty", filter: EtsEmpty },
  { name: "etscapitalize", filter: capitalize },
  { name: "etsnumber", filter: EtsNumber }
];

export default Filters;
