<template>
  <label class="ets-toggle" :class="size">
    <input type="checkbox" :checked="toggled" :class="size" @click="toggle()" />
    <span class="slider round" :class="size"></span>
  </label>
</template>

<script>
export default {
  name: "EtsToggle",
  props: {
    /**
     * Sizeof the toggle. Valid values: `xs, sm, md`
     */
    size: {
      type: String,
      default: "sm"
    },
    /**
     * To set the initial state of the toggle
     */
    toggled: {
      collapsed: Boolean,
      default: false
    }
  },
  methods: {
    toggle() {
      /**
       * When the user clicks the toggle
       */
      this.$emit("click", !this.toggled);
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-toggle {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 20px;

  &.md {
    width: 65px;
    height: 25px;
  }
  &.sm {
    width: 55px;
    height: 20px;
  }
  &.xs {
    width: 42px;
    height: 14px;
  }
}

.ets-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4e0df;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider {
  &:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    top: 1px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &.md:before {
    top: 2px;
    height: 20px;
    width: 20px;
  }
  &.sm:before {
    top: 1px;
    height: 18px;
    width: 18px;
  }
  &.xs:before {
    height: 13px;
    width: 13px;
    left: 2px;
  }
}

input:checked + .slider {
  background-color: $color-blue;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-blue;
}

input:checked + .slider {
  &:before {
    transform: translateX(30px);
  }
  &.md:before {
    transform: translateX(36px);
  }
  &.sm:before {
    transform: translateX(30px);
  }
  &.xs:before {
    transform: translateX(25px);
  }
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<docs>
```jsx
<div>
  <ets-toggle size="xs"/>
  <ets-toggle />
  <ets-toggle size="md"/>
</div>
```
</docs>
