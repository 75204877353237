<template>
  <section class="ets-header-search-wrapper" role="search">
    <article class="ets-header-search" :style="searchWidth">
      <label v-if="label" for="search" class="p-m search-label">{{ label }}</label>
      <ets-link-icon
        class="ets-header-search-button"
        title="Buscar"
        icon-name="search"
        disable-hover
        :icon-color-hover="!label ? 'white-transparent' : null"
        :href="searchUrl"
        :style="iconMargin"
      />
      <input
        class="ets-header-search-input"
        type="text"
        :placeholder="searchPlaceholder"
        v-model="searchText"
        @keyup.enter="submitSearch"
      />
      <ets-link-icon
        class="ets-header-search-reset"
        title="Borrar"
        icon-name="close"
        icon-color-hover="white-transparent"
        v-show="searchText"
        @click.native="clearSearch"
      />
    </article>
  </section>
</template>

<script>
import EtsLinkIcon from "@/components/Elements/EtsLinkIcon";

export default {
  name: "EtsNavBarSearch",
  components: {
    EtsLinkIcon
  },
  props: {
    baseUrlOld: {
      type: String,
      required: false
    },
    searchPlaceholder: {
      type: String,
      required: false,
      default: "Symbols"
    },
    label: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    searchText: ""
  }),
  computed: {
    intranetUrl() {
      return "//" + this.baseUrlOld;
    },
    searchUrl() {
      return this.label ? null : `${this.intranetUrl}/GlobalSearch.aspx?Q=${this.searchText}`;
    },
    searchWidth() {
      if (this.label) {
        return {
          width: "32rem"
        };
      }
      return null;
    },
    iconMargin() {
      if (this.label) {
        return {
          marginLeft: "1rem"
        };
      }
      return null;
    }
  },
  methods: {
    submitSearch() {
      if (this.searchPlaceholder === "Symbols") {
        window.location.href = this.searchUrl;
      } else {
        this.$emit("search", this.searchText);
      }
    },
    clearSearch() {
      this.searchText = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-header-search-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  height: 100%;
}
.ets-header-search {
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  display: inline-flex;
  flex-flow: row;
  margin: 0 1rem;
  width: 25rem;
  color: #9a9fa3;
}
.ets-header-search-input {
  background: transparent;
  border: none;
  color: #fff;
  height: 3.6rem;
  outline: 0;
  padding: 0.4rem 0.8rem;
  width: 17rem;
  font-size: 12px;
  &:focus + .ets-link-icon,
  &:focus + .ets-link-icon + .ets-link-icon {
    color: #fff;
  }
  &::placeholder {
    color: #aaadb1;
  }
}
.ets-link-icon.ets-header-search-button {
  font-size: 1.9rem;
  &:hover {
    text-decoration: none;
  }
}
.ets-header-search .ets-header-search-reset {
  font-size: 1.5rem;
  order: 3;
  &::after {
    color: #fff;
    top: 0;
  }
}
.ets-header-search .ets-link-icon {
  height: 3.6rem;
  width: 4rem;
}
.search-label {
  padding: 0 1.5rem;
  background: #4a525a;
  color: white;
  height: 3.6rem;
  display: flex;
  align-items: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    right: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1.8rem 0 1.8rem 12px;
    border-color: transparent transparent transparent #4a525a;
  }
}
</style>

<docs>
```jsx
<div class="navbar" style="width: 100%; background: #363f48; height: 50px;">
  <ets-nav-bar-search />
</div>
```
</docs>
