import Vue from "vue";
import App from "./App.vue";

import EtsToast from "@/components/Elements/EtsToast";

// Componentes
import Components from "@/components/index.js";

// Directivas
import Directives from "@/directives/index.js";

// Filtros
import Filters from "@/filters/index.js";

import Icons from "@/assets/icons/index.js";

// Day.js
import dayjs from "dayjs";

import ConfigStore from "@/configStore";

dayjs().format();

Components.install = (Vue = {}, options) => {
  if (options) {
    let store = new ConfigStore();
    store.set(options);
  }

  Icons.setup(Vue);

  for (const componentName in Components) {
    const component = Components[componentName];
    if (component && component.name !== "") Vue.component(component.name, component);
  }

  Directives.forEach(d => {
    Vue.directive(d.name, d.directive);
  });

  Filters.forEach(f => {
    Vue.filter(f.name, f.filter);
  });

  Vue.prototype.$EtsToast = new (Vue.extend(EtsToast))();
};

export default Components;

if (process.env.NODE_ENV === "development") {
  Icons.setup(Vue);
  new Vue({
    render: h => h(App)
  }).$mount("#app");
}
