<template>
  <div class="ets-wrapper">
    <div class="popup">
      <div class="header">
        <div class="title">{{ title }}</div>
        <button class="close-button" type="button" @click="close">
          <ets-icon icon="close" />
        </button>
      </div>
      <section class="content">
        <!-- @slot Use this slot to place content of the popup -->
        <slot />
      </section>
      <footer v-if="!hideFooter" class="footer">
        <!-- @slot Use this slot to place the footer buttons/actions -->
        <ets-button v-if="!hideButtons" @click="close" text="Cancel" />
        <slot name="footer" />
        <ets-button v-if="!hideButtons" @click="accept" mode="main" text="Accept" />
      </footer>
    </div>
    <div class="popup-bg" @click="close"></div>
  </div>
</template>

<script>
import EtsButton from "@/components/Elements/EtsButton";
import EtsIcon from "@/components/Elements/EtsIcon";

export default {
  name: "EtsPopup",
  components: {
    EtsButton,
    EtsIcon
  },
  props: {
    /**
     * Title of the popup displayed at the top
     */
    title: {
      type: String,
      default: ""
    },
    /**
     * Valid types: `accept-cancel, yes-no, none`
     */
    hideButtons: {
      type: Boolean,
      default: false
    },
    /**
     * To hide all footer
     */
    hideFooter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      /**
       * When the user clicks to close the popup
       */
      this.$emit("close");
    },
    /**
     * When the user clicks the accept button
     */
    accept() {
      this.$emit("accept");
    },
    addEventListeners() {
      document.addEventListener("keyup", this.escHandler);
    },
    removeEventListeners() {
      document.removeEventListener("keyup", this.escHandler);
    }
  },
  created() {
    this.escHandler = e => (e.keyCode === 27 ? this.close() : null);
    this.addEventListeners();
  },
  beforeDestroy() {
    this.removeEventListeners();
  }
};
</script>

<style lang="scss" scoped>
.ets-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.popup-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.popup {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 6px hsla(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 90%;
}
.header {
  font-size: 1.7rem;
  border-bottom: 1px solid #f0f0f0;
  flex-shrink: 0;
  color: #737373;
  @include font-header-l;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  padding: 1rem;
}
.content {
  padding: 2.5rem;
  flex-grow: 1;
  overflow: auto;
}
.footer {
  padding: 1rem;
  background: #f0f0f0;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  flex-shrink: 0;
}
.close-button {
  padding: 5px;
  display: flex;
  background-color: white;
  border: none;
  margin: 2% 0;
  &:hover {
    background-color: #737373;
  }
  &:hover ::v-deep path {
    fill: white;
  }
}
</style>

<docs>

**NOTE**: In the documentation the popup cannot be close but in your project this will work.
```vue
<template>
  <div>
    <ets-popup v-if="showPopup" title="Example popup" @close="closePopup">
      <p style="max-width: 400px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ultrices aliquam sapien, non bibendum nulla convallis et. Aenean ut libero turpis. Donec vel commodo erat, vel porta ipsum. </p>
    </ets-popup>
    <ets-button mode="main" @click="showPopup = !showPopup" text="Show Popup" @close="closePopup"></ets-button>
  </div>
</template>
<script>
export default {
  data: () => ({
    showPopup: false
  }),
  methods: {
    closePopup() {
      this.showPopup = false;
    }
  }
};
</script>
```
</docs>
