<template>
  <div class="form-input">
    <label :for="'#customInput-' + label" class="label">{{ label }}</label>
    <textarea
      v-if="textarea"
      :rows="rows"
      :value="value"
      class="input"
      :id="'#customInput-' + label"
      ref="customInput"
      :placeholder="placeholder ? placeholder : label"
      @input="updateValue()"
    />
    <input
      v-else
      :value="value"
      class="input"
      :id="'#customInput-' + label"
      ref="customInput"
      :type="type"
      :placeholder="placeholder ? placeholder : label"
      @input="updateValue()"
    />
    <small v-if="maxLength" class="max-length">{{ updatedValue.length }} / {{ maxLength }}</small>
    <ets-input-validation v-if="showAll" :showError="hasValidation" :name="label" :format="format" />
  </div>
</template>

<script>
import EtsInputValidation from "@/components/Elements/EtsInputValidation";

export default {
  name: "EtsInput",
  components: {
    EtsInputValidation
  },
  props: {
    /**
     * This prop if to allow the v-model from outside
     */
    value: {
      type: String,
      default: ""
    },
    /**
     * The label displayed above the input
     */
    label: {
      type: String,
      default: ""
    },
    /**
     * Placeholder for the inut
     */
    placeholder: {
      type: String,
      default: ""
    },
    /**
     * In case of textarea, number of rows
     */
    rows: {
      type: Number,
      default: 15
    },
    /**
     * To make the input a textarea
     */
    textarea: {
      type: Boolean,
      default: false
    },
    /**
     * Type of the input. Same valid values than the input (`text, email...`)
     */
    type: {
      type: String,
      default: "text"
    },
    /**
     * Max number of characters to allow in the input
     */
    maxLength: {
      type: Number,
      default: null
    },
    /**
     * To validate the input
     */
    hasValidation: {
      type: Boolean,
      default: false
    },
    /**
     * To show all validation errors if we have more than one validation
     */
    showAll: {
      type: Boolean,
      default: false
    },
    /**
     * To show the format needed in validation error
     */
    format: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    updatedValue: ""
  }),
  methods: {
    updateValue() {
      /**
       * Returns the value in the v-model
       */
      let value = this.$refs.customInput.value;
      let newValue = value;
      if (this.maxLength && value.length > this.maxLength) {
        newValue = value.substring(0, this.maxLength);
        this.$refs.customInput.value = newValue;
      }
      this.updatedValue = newValue;
      this.$emit("input", newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.label {
  display: block;
  margin-bottom: 0.7rem;
  font-weight: bold;
  font-size: 1.3rem;
  font-family: $font-title;
}
.input {
  width: 100%;
  background: none;
  border: 1px solid #e0e0e0;
  padding: 1rem;
}
textarea {
  resize: none;
}
.max-length {
  display: block;
  width: 100%;
  text-align: right;
}
</style>

<docs>
```jsx
<div>
  <ets-input placeholder="Default input example" />
  <ets-input placeholder="Input with label" label="Example label" />
  <ets-input placeholder="Input with type" label="Password input" type="password" />
  <ets-input placeholder="Textarea example" label="Textarea example" textarea :rows="5"/>
</div>
```
</docs>
