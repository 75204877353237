<template>
  <select class="selector" :disabled="disabled" v-model="selected" @change="onChange()">
    <option v-for="item in items" :value="item" :key="item.id">
      {{ item.text }}
    </option>
  </select>
</template>

<script>
export default {
  name: "EtsDropdown",
  props: {
    /**
     * This prop is to generate the options
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * No event is emitted
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * This prop if to allow the v-model from outside
     */
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    selected: null
  }),
  methods: {
    onChange() {
      this.$emit("input", this.selected);
    }
  },
  created() {
    this.selected = this.value;
  }
};
</script>

<style lang="scss" scoped>
.selector {
  height: 30px;
  background-color: white;
  color: rgb(79, 79, 79);
  font-size: 1.6rem;
  line-height: 2.4rem;
  &:hover,
  &:active,
  &:focus {
    background-color: #f5f5f5;
    color: rgb(79, 79, 79);
  }
}
</style>

<docs>
```jsx
const selected = { text: "Dos", value: "B" },
const items = [
  { text: "Uno", value: "A" },
  { text: "Dos", value: "B" },
  { text: "Tres", value: "C" }
]
<ets-dropdown v-model="selected" :items="items" />
<ets-dropdown v-model="selected" :items="items" disabled />
```
</docs>
