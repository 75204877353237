<template>
  <span class="spinner-container" :style="spinnerStyle">
    <svg
      class="spinner"
      version="1.1"
      id="loader-1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style="enable-background: new 0 0 50 50"
      :style="{ width: size + 'px', height: size + 'px' }"
      xml:space="preserve"
    >
      <path
        :fill="color"
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
    <span v-if="text" class="spinner-text">{{ text }}</span>
  </span>
</template>

<script>
export default {
  name: "EtsSpinner",
  props: {
    color: {
      type: String,
      default: "#363f48"
    },
    size: {
      type: Number,
      default: 65
    },
    text: {
      type: String,
      default: ""
    },
    center: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    spinnerStyle() {
      if (this.center) {
        return {
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        };
      }
      if (!this.inline) {
        return {
          display: "block"
        };
      }
      return null;
    }
  }
};
</script>

<style>
.spinner-container {
  position: relative;
  text-align: center;
}
</style>

<docs>
```jsx
  <ets-spinner />
```
</docs>
