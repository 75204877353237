<template>
  <div v-if="tabs">
    <ets-tabs>
      <ets-tab-item v-for="tabKey in tabsKeys" :key="tabKey" :id="tabKey" :name="capitalize(tabKey)">
        <table class="table">
          <tbody>
            <ets-json-tree-row
              v-for="(value, key) in tabs[tabKey]"
              :value="value"
              :label="key"
              :show-all-childrens="showAllChildrens"
              :key="key"
              :open="open"
            ></ets-json-tree-row>
          </tbody>
        </table>
      </ets-tab-item>
      <ets-tab-item id="others" v-if="Object.keys(valueNoTabs).length !== 0" :name="capitalize('others')">
        <table class="table">
          <tbody>
            <ets-json-tree-row
              v-for="(value, key) in valueNoTabs"
              :value="value"
              :label="key"
              :show-all-childrens="showAllChildrens"
              :key="key"
              :open="open"
            ></ets-json-tree-row>
          </tbody>
        </table>
      </ets-tab-item>
    </ets-tabs>
  </div>
  <ets-table v-else-if="tableColumns" :columns="tableColumns" :data="value" style="width: 100%" class="list">
    <template slot="row" slot-scope="data">
      <td v-for="(column, k) in data.row" :key="k">{{ column }}</td>
    </template>
  </ets-table>

  <table v-else-if="isListOfLists" class="list">
    <tbody>
      <tr v-for="(list, j) in value" :key="j">
        <td v-for="item in list" :key="item">{{ item }}</td>
      </tr>
    </tbody>
  </table>
  <span v-else-if="isLabel" :class="computedClass(value)" v-html="value"></span>
  <span v-else-if="value === null" class="label-empty">(Empty value)</span>
  <span v-else-if="value.length === 0" class="label-empty">(Empty list)</span>
  <span v-else-if="Object.keys(value).length === 0" class="label-empty">(Empty object)</span>
  <table v-else-if="isObject" class="table">
    <tbody>
      <ets-json-tree-row
        v-for="(value, key) in value"
        :value="value"
        :label="key"
        :show-all-childrens="showAllChildrens"
        :key="key"
        :open="open"
      ></ets-json-tree-row>
    </tbody>
  </table>
</template>

<script>
import EtsJsonTreeRow from "@/components/Elements/EtsJsonTreeRow";
import EtsTable from "@/components/Elements/EtsTable";
import EtsTabs from "@/components/Elements/EtsTabs";
import EtsTabItem from "@/components/Elements/EtsTabItem";

export default {
  name: "EtsJsonTree",
  components: {
    EtsJsonTreeRow,
    EtsTable,
    EtsTabs,
    EtsTabItem
  },
  props: {
    /**
     * This prop is used internally
     */
    label: {
      type: [String, Number],
      default: null
    },
    /**
     * The JSON tree to display
     */
    value: {
      type: [Object, Array],
      required: true
    },
    /**
     * To display or not all the childrens
     */
    showAllChildrens: {
      type: Boolean,
      default: false
    },
    /**
     * Label of the JSON to open at the first time
     */
    open: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    fieldsObject: null,
    valueNoTabs: null
  }),
  methods: {
    computedClass(val) {
      switch (typeof val) {
        case "string":
          return "label-string";
        case "number":
          return "label-number";
        case "boolean":
          return val ? "label-true" : "label-false";
      }
    },
    capitalize(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  computed: {
    tableColumns() {
      if (this.value && Array.isArray(this.value) && typeof this.value[0] === "object" && !Array.isArray(this.value[0])) {
        let keys = Object.keys(this.value[0]);
        return keys.map(key => {
          return { text: key, sorteable: true, key: key };
        });
      }
      return null;
    },
    tabs() {
      return this.value != null ? this.value["tabs"] : "";
    },
    tabsKeys() {
      return this.tabs ? Object.keys(this.tabs) : [];
    },
    isObject() {
      return typeof this.value === "object";
    },
    isListOfLists() {
      return this.value && Array.isArray(this.value) && Array.isArray(this.value[0]);
    },
    isLabel() {
      return typeof this.value === "string" || typeof this.value === "number" || typeof this.value === "boolean";
    }
  },
  created() {
    if (this.tabs) {
      this.valueNoTabs = Object.assign({}, this.value);
      delete this.valueNoTabs.tabs;
      this.valueNoTabs = Object.freeze(this.valueNoTabs);
    }
  }
};
</script>

<style scoped lang="scss">
.label-string {
  color: #6e6e6e;
}
.label-number {
  color: dodgerblue;
}
.label-true {
  color: darkgreen;
}
.label-false {
  color: crimson;
}
.label-empty {
  color: grey;
  font-style: italic;
}
.table {
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;
  font-size: 13px;
  color: #444;
}
.table > tbody > tr:last-child {
  border-bottom: 1px solid #ddd;
}
.table > tbody > tr {
  border: 1px solid #ddd;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom: none;
  &:last-child {
    border-bottom-color: transparent !important;
  }

  &:first-child {
    border-top-color: transparent;
  }
}
.table > tbody > tr:hover {
  border: 1px solid #f99927 !important;
}
</style>

<docs>

**NOTE**: For the tabs, you have to create a **"tabs"** object. All keys inside this object will
gonna be tabs. Inside the hab you can create the JSON you want to display inside the tag.
The object or properties outside tabs will be displayed in a tab called **Others**.
```jsx
let jsonData = {
    tabs: {
      tab1: {
        reco_format_output: "weights",
        universe: [
          { tags: [1, 3, 45], symbolId: 118195 },
          { tags: "werea", symbolId: 112280 },
          { tags: "rewa", symbolId: 90801 },
          { tags: "ewqewqa", symbolId: 112254 },
          { tags: "rrew", symbolId: 118179 },
          { tags: "ewqweqa", symbolId: 48046 },
          { tags: "rewrewa", symbolId: 118224 },
          { tags: "ee", symbolId: 118143 }
        ]
      },
      tab2: {
        riskLevel: 6,
        constraints: [],
        clave: [1, 2, 3, 4, 5, 6]
      }
    },
    constraints: [],
    reco_format_output: "weights",
    universe_type: "finhub",
    universe: [
      { tags: "aaa", symbolId: 118195 },
      { tags: "werea", symbolId: 112280 },
      { tags: "rewa", symbolId: 90801 },
      { tags: "ewqewqa", symbolId: 112254 },
      { tags: "rrew", symbolId: 118179 },
      { tags: "ewqweqa", symbolId: 48046 },
      { tags: "rewrewa", symbolId: 118224 },
      { tags: "ee", symbolId: 118143 }
    ],
    id: "mt16",
    adaptationLevel: [
      [1, 2, 3, 4, 5],
      [6, 7, 8, 9, 10],
      [11, 12, 13, 14, 15],
      [16, 17, 18, 19, 20]
    ],
    riskLevel: 6
}
<ets-json-tree :value="jsonData" />
```
```jsx
let jsonData2 = {
    tabs: {
      tab1: {
        reco_format_output: "weights",
        universe: [
          { tags: [1, 3, 45], symbolId: 118195 },
          { tags: "werea", symbolId: 112280 },
          { tags: "rewa", symbolId: 90801 },
          { tags: "ewqewqa", symbolId: 112254 },
          { tags: "rrew", symbolId: 118179 },
          { tags: "ewqweqa", symbolId: 48046 },
          { tags: "rewrewa", symbolId: 118224 },
          { tags: "ee", symbolId: 118143 }
        ]
      },
      tab2: {
        riskLevel: 6,
        constraints: [],
        clave: [1, 2, 3, 4, 5, 6]
      }
    },
    constraints: [],
    reco_format_output: "weights",
    universe_type: "finhub",
    universe: [
      { tags: "aaa", symbolId: 118195 },
      { tags: "werea", symbolId: 112280 },
      { tags: "rewa", symbolId: 90801 },
      { tags: "ewqewqa", symbolId: 112254 },
      { tags: "rrew", symbolId: 118179 },
      { tags: "ewqweqa", symbolId: 48046 },
      { tags: "rewrewa", symbolId: 118224 },
      { tags: "ee", symbolId: 118143 }
    ],
    id: "mt16",
    adaptationLevel: [
      [1, 2, 3, 4, 5],
      [6, 7, 8, 9, 10],
      [11, 12, 13, 14, 15],
      [16, 17, 18, 19, 20]
    ],
    riskLevel: 6
}
<p>With all the childrens opened</p>
<ets-json-tree :value="jsonData2" show-all-childrens />

```

```jsx
let jsonData3 = {
    tabs: {
      tab1: {
        reco_format_output: "weights",
        universe: [
          { tags: [1, 3, 45], symbolId: 118195 },
          { tags: "werea", symbolId: 112280 },
          { tags: "rewa", symbolId: 90801 },
          { tags: "ewqewqa", symbolId: 112254 },
          { tags: "rrew", symbolId: 118179 },
          { tags: "ewqweqa", symbolId: 48046 },
          { tags: "rewrewa", symbolId: 118224 },
          { tags: "ee", symbolId: 118143 }
        ]
      },
      tab2: {
        riskLevel: 6,
        constraints: [],
        clave: [1, 2, 3, 4, 5, 6]
      }
    },
    constraints: [],
    reco_format_output: "weights",
    universe_type: "finhub",
    universe: [
      { tags: "aaa", symbolId: 118195 },
      { tags: "werea", symbolId: 112280 },
      { tags: "rewa", symbolId: 90801 },
      { tags: "ewqewqa", symbolId: 112254 },
      { tags: "rrew", symbolId: 118179 },
      { tags: "ewqweqa", symbolId: 48046 },
      { tags: "rewrewa", symbolId: 118224 },
      { tags: "ee", symbolId: 118143 }
    ],
    id: "mt16",
    adaptationLevel: [
      [1, 2, 3, 4, 5],
      [6, 7, 8, 9, 10],
      [11, 12, 13, 14, 15],
      [16, 17, 18, 19, 20]
    ],
    riskLevel: 6
}
<p>With the label universe opened</p>
<ets-json-tree :value="jsonData3" open="universe" />
```

</docs>
