<template>
  <form enctype="multipart/form-data" novalidate class="ets-file-uploader">
    <label :for="id" class="custom-file-upload" :class="{ disabled: disabled || loading }">
      <ets-spinner v-if="loading" color="white" :size="28" center />
      <span v-else-if="textButton" class="text">{{ textButton }}</span>
    </label>
    <input
      :id="id"
      type="file"
      :name="uploadFieldName"
      :disabled="disabled || loading"
      @change="filesChange($event.target.name, $event.target.files)"
      class="input-file"
    />
    <p v-if="!fileName && displayFilename">No chosen file</p>
    <p v-if="fileName && displayFilename">{{ fileName }}</p>
    <ets-input-validation v-if="showAll" :showError="hasValidation" :name="labelName" />
  </form>
</template>

<script>
import EtsSpinner from "@/components/Elements/EtsSpinner";
import EtsInputValidation from "@/components/Elements/EtsInputValidation";

export default {
  name: "EtsFileUploader",
  components: {
    EtsSpinner,
    EtsInputValidation
  },
  props: {
    /**
     * Id of the input
     */
    id: {
      type: String,
      default: "file-upload"
    },
    /**
     * The name of the input
     */
    uploadFieldName: {
      type: String,
      default: "files"
    },
    /**
     * To disable the button
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * To display or not the filename
     */
    displayFilename: {
      type: Boolean,
      default: true
    },
    /**
     * To change the text inside the button
     */
    textButton: {
      type: String,
      default: "Select a file"
    },
    /**
     * To display spinner inside the button
     */
    loading: {
      type: Boolean,
      default: false
    },
    hasValidation: {
      type: Boolean,
      default: false
    },
    /**
     * To show all validation errors if we have more than one validation
     */
    showAll: {
      type: Boolean,
      default: false
    },
    /**
     * To show the name of the wrong field in the validation error message
     */
    labelName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      uploadedFile: [],
      uploadError: null,
      currentStatus: null,
      fileName: ""
    };
  },
  methods: {
    reset() {
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name);
        this.fileName = fileList[x].name;
      });

      /** When the user uploads the file
       * @type {object}
       */
      this.$emit("uploaded", formData);
    }
  },
  mounted() {
    this.reset();
  }
};
</script>

<style lang="scss" scoped>
$main1: map-get($button_colors, "main1");
$main2: map-get($button_colors, "main2");
$main3: map-get($button_colors, "main3");
$color: map-get($button_colors, "color");
$text_size: map-get($button_config, "text_size");
$padding: map-get($button_config, "padding");

.input-file {
  display: none;
}
.custom-file-upload {
  display: inline-block;
  padding: 0.7rem 1.9rem;
  background: $main1;
  background-image: -webkit-linear-gradient(top, $main1, $main2);
  border: 1px solid $main3;
  color: $color;
  min-width: 40px;
  min-height: 3.6rem;
  border-radius: 2px;
  outline: none;
  margin: 0 4px;
  &:hover {
    background: $main3;
    background-image: -webkit-linear-gradient(top, $main1, $main3);
    border: 1px solid rgba(0, 0, 0, 0.17);
    color: $color;
  }
  &.disabled {
    color: #ddd !important;
    border: 1px solid rgba(0, 0, 0, 0.17);
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
      color: #ddd;
      background-color: #fcfcfc;
    }
  }
}
.text {
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-size: $text_size;
  white-space: nowrap;
}
.ets-file-uploader {
  display: flex;
  align-items: center;
}
</style>

<docs>
```vue
<ets-file-uploader />
<ets-file-uploader text-button="New file" :loading="isLoading" />
```
</docs>
