<template>
  <div class="input-number">
    <input
      ref="inputNumber"
      class="input"
      type="text"
      :min="minValue"
      :max="maxValue"
      :value="parsedValue"
      @input="onInput"
      :style="{ width: inputWidth }"
    />
    <div class="buttons">
      <div class="input-button input-up" tabindex="-1" @click="plus">
        <ets-icon class="up" icon="chevron-up" width="20px" height="20px" />
      </div>
      <div class="input-button input-down" tabindex="-1" @click="minus">
        <ets-icon class="down" icon="chevron-down" width="20px" height="20px" />
      </div>
    </div>
  </div>
</template>

<script>
import EtsIcon from "@/components/Elements/EtsIcon";

export default {
  name: "EtsInputNumber",
  components: {
    EtsIcon
  },
  props: {
    /**
     * Min value
     */
    minValue: {
      type: Number,
      default: 0
    },
    /**
     * Max value
     */
    maxValue: {
      type: Number,
      default: 10
    },
    /**
     * Initial value
     */
    value: {
      type: Number,
      default: 0
    },
    /**
     * If enabled, the text is formatted `00:00`
     */
    time: {
      type: Boolean,
      default: false
    },
    /**
     * Thw width of the input
     */
    inputWidth: {
      type: String,
      default: "65px"
    }
  },
  computed: {
    parsedValue() {
      return this.parseValue(this.value);
    }
  },
  methods: {
    parseValue(value) {
      if (!this.time) {
        return value;
      } else {
        return value < 10 ? "0" + value : value;
      }
    },
    onInput(event) {
      const newValue = parseInt(event.target.value);
      const clampedValue = Math.min(newValue, this.maxValue) || 0;
      this.$emit("input", clampedValue);
      this.$refs.inputNumber.value = this.parseValue(clampedValue);
    },
    plus() {
      if (this.value < this.maxValue) {
        this.$refs.inputNumber.value = this.parseValue(this.value + 1);
        this.$emit("input", this.value + 1);
      } else {
        this.$emit("input", this.minValue);
      }
    },
    minus() {
      if (this.value > this.minValue) {
        this.$refs.inputNumber.value = this.parseValue(this.value - 1);
        this.$emit("input", this.value - 1);
      } else {
        this.$emit("input", this.maxValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-number {
  height: 4rem;
  display: flex;
  align-items: center;
}
.input {
  height: 100%;
  border: 1px solid #e5e5e5;
  background: white;
  font-size: 1.4rem;
  letter-spacing: 0.25px;
  text-align: center;
  -moz-appearance: textfield;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  background: #e5e5e5;
  border: 1px solid #d4d4d4;
  width: 4rem;
  position: relative;
  outline: none;
  transition: background 0.2s ease-in-out;
  &:hover {
    background: #dadada;
  }
}
.input-up {
  border-bottom: none;
}
.input-down {
  border-top: none;
}
.up {
  position: absolute;
  top: 2px;
}
.down {
  position: absolute;
  bottom: 2px;
}
</style>

<docs>

```jsx
<ets-input-number />
```

With initial value:

```jsx
<ets-input-number :value="7"/>
```

With time formatting:

```jsx
<ets-input-number time/>
```

With min and max values:

```jsx
<ets-input-number :min="2" :max="5" :value="3"/>
```
</docs>
