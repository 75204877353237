<template>
  <table class="list" :class="{ fill: fill }">
    <thead>
      <tr>
        <slot name="column">
          <th
            v-for="(column, i) in columns"
            :colspan="columnColSpan(column)"
            :key="i"
            @click="sortColumn(column)"
            :class="['header', { sorteable: isSorteable(column) }, columnClass(column)]"
          >
            <template>{{ getColumnName(column) }}</template>
            <sup v-if="getColumnSup(column)" class="sup-number">{{ getColumnSup(column) }}</sup>
            <ets-icon v-if="column.icon" :icon="column.icon" class="header-icon" />
            <span class="sort-icons" v-if="isSorteable(column)">
              <ets-icon
                :class="{ 'icon-disabled': !arrow(column) || arrow(column) === 'desc' }"
                icon="chevron-up"
                class="icon-up"
                width="15px"
                height="15px"
              />
              <ets-icon
                :class="{ 'icon-disabled': !arrow(column) || arrow(column) === 'asc' }"
                icon="chevron-down"
                class="icon-down"
                width="15px"
                height="15px"
              />
            </span>
          </th>
          <th v-if="checksEnabled">
            <input class="check-header" type="checkbox" :checked="selectAll" @click="toggleAllRows" />
          </th>
        </slot>
      </tr>
      <tr v-show="filtersEnabled">
        <th v-for="(column, i) in columns" :key="i">
          <input v-model="filterKeys[column.key]" size="1" />
        </th>
        <th v-if="checksEnabled"></th>
      </tr>
    </thead>
    <tbody>
      <template v-if="isObject(data) && data.length !== 0">
        <template v-for="(item, i) in sortedData">
          <tr :key="i">
            <slot name="row" :row="item">
              <td v-for="(column, j) in columns" :key="j">
                {{ itemValue(item, column) }}
              </td>
            </slot>
            <td v-if="checksEnabled">
              <input type="checkbox" v-model="selected" :value="item[itemKey]" @change="selectRow" />
            </td>
          </tr>
        </template>
      </template>
      <template v-else>
        <slot name="row" />
      </template>
    </tbody>
    <tfoot>
      <tr>
        <slot name="footer" />
      </tr>
    </tfoot>
  </table>
</template>
<script>
import Vue from "vue";
import EtsIcon from "@/components/Elements/EtsIcon";
import dayjs from "dayjs";

export default {
  name: "EtsTable",
  components: {
    EtsIcon
  },
  props: {
    /**
     * Array to configure the tabs
     */
    columns: {
      type: Array,
      default: null
    },
    /**
     * Data to put inside the table
     */
    data: {
      type: Array,
      default: () => []
    },
    /**
     * Filters for each column
     */
    filtersEnabled: {
      type: Boolean,
      default: false
    },
    /**
     * Full width table
     */
    fill: {
      type: Boolean,
      default: false
    },
    /**
     * Check for each row and emit checked data to its parent needs to specify itemKey
     */
    checksEnabled: {
      type: Boolean,
      default: false
    },
    /**
     * The property on each item that is used as a unique key when chckbox enabled
     */
    itemKey: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    currentSort: "",
    currentSortDir: "asc",
    filterKeys: {},
    filtered: true,
    selected: [],
    selectAll: false
  }),
  computed: {
    filteredData() {
      let dataAux = this.data;
      if (this.filtersEnabled) {
        Object.keys(this.filterKeys).forEach(key => {
          if (this.filterKeys[key] && this.filterKeys[key] !== "") {
            dataAux = dataAux.filter(row => String(row[key]).toLowerCase().indexOf(this.filterKeys[key].toLowerCase()) > -1);
          }
        });
      }
      return dataAux;
    },
    sortedData() {
      return this.filteredData.slice().sort((a, b) => {
        let x = a[this.currentSort];
        let y = b[this.currentSort];

        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;

        let compare1 = this.formatToCompare(x);
        let compare2 = this.formatToCompare(y);

        if (compare1 < compare2) return -1 * modifier;
        if (compare1 > compare2) return 1 * modifier;

        if (compare1 === "-" || !compare1) return -1 * modifier;
        if (compare2 === "-" || !compare2) return 1 * modifier;

        return 0;
      });
    }
  },
  created() {
    this.createFilters();
  },
  methods: {
    createFilters() {
      this.columns.forEach(column => {
        Vue.set(this.filterKeys, column.key, "");
      });
    },
    sortColumn(column) {
      let key = this.getColumnKey(column);
      if (key === this.currentSort && this.isSorteable(column)) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = key;
      this.filtered = false;
    },
    itemValue(item, column) {
      let key = this.getColumnKey(column);
      let value = item[key];
      return value;
    },
    getColumnKey(column) {
      if (this.isObject(column)) {
        if (column.key) {
          return column.key;
        } else {
          return column.text;
        }
      } else {
        return column;
      }
    },
    getColumnName(column) {
      return this.isObject(column) ? column.text : column;
    },
    getColumnSup(column) {
      if (this.isObject(column)) {
        return column.sup;
      }
    },
    isObject(object) {
      return typeof object === "object";
    },
    arrow(column) {
      let key = this.getColumnKey(column);
      if (this.currentSort === key && this.isSorteable(column)) {
        return this.currentSortDir;
      }
      return null;
    },
    columnClass(column) {
      if (column.class) {
        return column.class;
      }
    },
    columnColSpan(column) {
      if (column.colspan) {
        return column.colspan;
      } else {
        return 1;
      }
    },
    isSorteable(column) {
      if (!this.isObject(column)) return false;
      return typeof column.sorteable === "undefined" ? true : column.sorteable;
    },
    formatToCompare(x) {
      if (typeof x === "number") {
        return x;
      } else if (dayjs(x).isValid()) {
        return dayjs(x);
      } else if (typeof x === "string") {
        return x.toLowerCase();
      }
      return x;
    },
    toggleAllRows() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selected = this.sortedData.map(item => item[this.itemKey]);
      } else {
        this.selected = [];
      }
    },
    resetSelected() {
      this.selected = [];
      this.selectAll = false;
    },
    selectRow() {
      if (this.selectAll) {
        this.selectAll = false;
      }
      if (this.selected.length === this.sortedData.length) {
        this.selectAll = true;
      }
    }
  },
  watch: {
    sortedData: {
      handler() {
        // Para evitar un reset en el componente padre si entran datos de forma asincrona (por ejemplo websocket)
        let selection = this.sortedData.filter(item => this.selected.includes(item[this.itemKey]));
        this.selected = selection.map(item => item[this.itemKey]);
        if (this.sortedData.length !== this.selected.length) {
          if (this.selectAll && this.sortedData.length > this.selected.length) {
            this.selectAll = false;
          }
        }
      },
      immediate: true
    },
    filterKeys: {
      handler() {
        this.resetSelected();
      },
      deep: true,
      immediate: true
    },
    filtersEnabled() {
      this.resetSelected();
    },
    selected() {
      this.$emit("selected-data", this.selected);
    }
  }
};
</script>
<style scoped lang="scss">
th input {
  width: 100%;
}
.input-header {
  width: 100%;
}
.sorteable {
  cursor: pointer;
  padding-right: 2.4rem !important;
}
.header {
  vertical-align: middle;
}
.sort-icon {
  height: 100%;
  position: relative;
  margin-left: 0.5rem;
}
.sup-number {
  font-size: 80% !important;
  padding: 0 0.2em;
  color: #a9a9a9;
}
.sort-icons {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  margin-left: 0.5rem;
  & ::v-deep path {
    fill: black;
  }
}
.icon-up {
  position: absolute;
  top: calc(50% - 4px);
  transform: translate(0, -50%);
}
.icon-down {
  position: absolute;
  top: calc(50% + 4px);
  transform: translate(0, -50%);
}
.icon-disabled ::v-deep path {
  fill: #acacac;
}
.check-header {
  width: unset;
}
</style>

<docs>

**NOTE**: For the table to be able to sort the elements it is necessary to define a key to the column,
which has to coincide with the name of the property of the data object that we pass as prop.

```jsx
const columns = [
  {
    text: "A",
    colspan: 1,
    key: "text",
    sup: 3
  },
  {
    text: "B",
    colspan: 1,
    key: "title"
  },
  {
    text: "C",
    colspan: 1,
    key: "img"
  }
]

const data = [
  {
    text: "09:02:17",
    title: "E",
    img: "25 Apr 2018"
  },
  {
    text: "09:42:27",
    title: "A",
    img: "-"
  },
  {
    text: "09:10:27",
    title: "F",
    img: "10 Jun 2018"
  }
]

<ets-table :columns="columns" :data="data" fill />
```

```jsx
const columns2 = [
  {
    text: "A",
    colspan: 1,
    key: "text",
    sup: 3
  },
  {
    text: "B",
    colspan: 1,
    key: "title"
  },
  {
    text: "C",
    colspan: 1,
    key: "img"
  }
]

const data2 =  {
  text: "09:02:17",
  title: "i",
  img: "25 Apr 2018"
}

<ets-table :columns="columns2" fill>
  <tr slot="row">
    <td>{{ data2.text }}</td>
    <td>{{ data2.img }}</td>
    <td>Example</td>
  </tr>
</ets-table>
```

```jsx
const columns3 = [
  {
    text: "A",
    colspan: 1,
    key: "text"
  },
  {
    text: "B",
    colspan: 1,
    key: "title"
  },
  {
    text: "C",
    colspan: 1,
    key: "img"
  }
]

const data3 = [
  {
    text: "09:02:17",
    title: "i",
    img: "25 Apr 2018"
  },
  {
    text: "09:42:27",
    title: "W",
    img: "-"
  },
  {
    text: "09:10:27",
    title: "F",
    img: "10 Jun 2018"
  },
  {
    text: "09:10:27",
    title: "w",
    img: "10 Jun 2017"
  }
]

<ets-table
  :data="data3"
  :columns="columns3"
  class="list"
  :filtersEnabled="true"
  :checksEnabled="true"
>
</ets-table>
```
</docs>
