<template>
  <div>
    <h1 class="title">{{ title }} {{ code }}</h1>
    <p class="error header-l" v-if="type">{{ type }}</p>
    <div v-if="message" class="traceback">
      <p class="p-l">Traceback from server:</p>
      <p class="p-m">{{ message }}</p>
    </div>
  </div>
</template>

<script>
const errors = {
  400: "Bad request",
  404: "Not found",
  403: "You don't have permission to perform this action",
  500: "Internal server error",
  502: "Bad gateway"
};

export default {
  name: "EtsPageError",
  data: () => ({
    message: "",
    title: "Error",
    code: "",
    type: "Service Unavailable"
  }),
  props: {
    error: Error
  },
  mounted() {
    if (this.error && this.error.response) {
      let response = this.error.response;
      this.code = response.status;
      this.type = errors[this.code];
      if (response.data.error) {
        this.message = response.data.error.message;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  font-size: 15rem;
  text-align: center;
  color: #d6d6d6;
  margin: 7rem 0 0;
}
.error {
  text-align: center;
  max-width: 850px;
  margin: 0 auto;
  color: #dc0000;
}
.traceback {
  max-width: 850px;
  margin: 0 auto;
  margin-top: 10rem;
  & p {
    text-align: center;
  }
}
</style>
