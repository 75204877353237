<template>
  <div>
    <label v-if="radio" class="form-input input-form-radio" :class="size">
      {{ text }}
      <input ref="radio" type="radio" class="radio" :class="size" name="radio" :value="text" :checked="checked" @input="updateRadio()" />
      <span class="checkmark" :class="size"></span>
    </label>
    <label v-else class="form-input input-form-checkbox" :class="size">
      {{ text }}
      <input ref="checkbox" class="checkbox" :class="size" type="checkbox" :value="text" :checked="checked" @input="updateCheckbox()" />
      <span class="checkmark-checkbox" :class="size"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "EtsCheckboxRadio",
  props: {
    /**
     * This prop if to allow the v-model from outside
     */
    value: {
      type: [String, Boolean, Array],
      default: false
    },
    /**
     * To check or uncheck from outside
     */
    checked: {
      type: Boolean,
      default: false
    },
    /**
     * Label for the checkbox / radio
     */
    text: {
      type: String,
      default: ""
    },
    /**
     * To display the radio button
     */
    radio: {
      type: Boolean,
      default: false
    },
    /**
     * Return a list of checks
     */
    list: {
      type: Boolean,
      default: false
    },
    /**
     * To return a boolean in the v-model. By default returns the text
     */
    boolean: {
      type: Boolean,
      default: false
    },
    /**
     * Size. Allowed values Valid types: `xs, md, sm, xl `
     */
    size: {
      type: String,
      default: "md"
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  methods: {
    updateCheckbox() {
      if (this.list) {
        const element = this.$refs.checkbox.value;
        const index = this.innerValue.indexOf(element);
        if (index > -1) {
          this.innerValue.splice(index, 1);
        } else {
          this.innerValue.push(element);
        }
        this.$emit("input", this.innerValue);
      } else {
        if (this.boolean) {
          this.$emit("input", !this.innerValue);
        } else {
          this.$emit("input", this.$refs.checkbox.value);
        }
      }
    },
    updateRadio() {
      if (this.boolean) {
        /**
         * Returns the value in the v-model
         */
        this.$emit("input", !this.innerValue);
      } else {
        this.$emit("input", this.$refs.radio.value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-form-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 1.4rem;
  cursor: pointer;
  user-select: none;
  &.xs {
    padding-left: 2.5rem;
  }
}
.radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  left: 0;
  height: 2rem;
  width: 2rem;
  border: 1px solid #828282;
  border-radius: 50%;
  transition: all 0.3s;
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: $color-blue;
  }
  &.xs {
    height: 1.4rem;
    width: 1.4rem;
    &:after {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
}
.input-form-radio .radio:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  &.xs:after {
    width: 0.6rem;
    width: 0.6rem;
  }
}
.input-form-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 3rem;
  margin-bottom: 1.4rem;
  cursor: pointer;
  user-select: none;
  &.xs {
    padding-left: 2.5rem;
  }
}
.input-form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark-checkbox {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  height: 2rem;
  width: 2rem;
  border-radius: 0.5rem;
  border: 1px solid #828282;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
  &.xs {
    height: 1.4rem;
    width: 1.5rem;
    border-radius: 0.2rem;
  }
}
.input-form-checkbox input:checked ~ .checkmark-checkbox:after {
  display: block;
}
.input-form-checkbox .checkmark-checkbox:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid $color-blue;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkmark-checkbox.xs:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid $color-blue;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>

<docs>
```
<ets-checkbox-radio text="Checkbox example"/>
<ets-checkbox-radio text="Radio example" radio/>
```

When the prop **list** is activated, the return of the value in the v-model is a **list**.
You can use this to return the list of checks that the user has activated, this way you don't have to build your list for a list of checks.
If the user unchecks the value, the component will delete it from the list, and if the user checks the value, the component will add it.

Example of returns:

```vue
<template>
  <div class="example">
    Checkbox with value:
    <span>{{checkbox}}</span>
    <ets-checkbox-radio v-model="checkbox" text="Checkbox example return label" />
    Checkbox with boolean:
    <span>{{checkboxBool}}</span>
    <ets-checkbox-radio v-model="checkboxBool" text="Checkbox example return boolean" boolean />

    Multiple radio with value:
    <span>{{radio}}</span>
    <ets-checkbox-radio v-model="radio" text="Radio 1" radio/>
    <ets-checkbox-radio v-model="radio" text="Radio 2" radio/>

    Radio with boolean:
    <span>{{radioBool}}</span>
    <ets-checkbox-radio v-model="radioBool" text="Radio 1" radio boolean/>
  </div>
</template>

<script>
export default {
  data: () => ({
    checkbox: "",
    checkboxBool: false,
    radio: "",
    radioBool: false
  })
};
</script>
```


</docs>
