<template>
  <tr>
    <th v-if="label !== undefined" class="key label" :class="{ label_childrens: isObject }" @click.stop="toggleChildren">
      {{ label }}
      <span v-if="isObject" class="arrow">{{ arrow }}</span>
    </th>
    <td
      v-if="!showChildren && isObject"
      class="value collapsed_object"
      :class="{ value_childrens: !showChildren && isObject }"
      @click.stop="toggleChildren"
    >
      {{ isArray ? "[...]" : "{...}" }}
    </td>
    <td v-else class="value" :class="{ value_childrens: !showChildren && isObject }">
      <ets-json-tree :show-all-childrens="showAllChildrens" :value="value" :label="label"></ets-json-tree>
    </td>
  </tr>
</template>

<script>
export default {
  name: "EtsJsonTreeRow",
  props: {
    label: [String, Number],
    value: null,
    showAllChildrens: Boolean,
    open: String
  },
  data: () => ({
    showChildren: false
  }),
  methods: {
    toggleChildren() {
      if (this.isObject || this.isArray) this.showChildren = !this.showChildren;
    }
  },
  computed: {
    arrow() {
      return this.showChildren ? "▲" : "▼";
    },
    isArray() {
      return this.value && Array.isArray(this.value);
    },
    isTab() {
      return this.isObject && this.value.tab;
    },
    isObject() {
      return this.value && typeof this.value === "object" && this.value.length !== 0 && Object.keys(this.value).length !== 0;
    }
  },
  watch: {
    showAllChildrens() {
      this.showChildren = this.showAllChildrens;
    },
    open() {
      if (this.label === this.open) {
        this.showChildren = true;
      }
    }
  },
  beforeCreate: function () {
    this.$options.components.EtsJsonTree = require("./EtsJsonTree.vue").default;
  },
  created() {
    if (this.showAllChildrens) this.showChildren = this.showAllChildrens;
    if (this.label === this.open) {
      this.showChildren = true;
    }
  }
};
</script>

<style scoped lang="scss">
.value {
  border-left: 1px solid #ddd;
  font-weight: 500;
  padding: 5px;
  vertical-align: middle;
  overflow-x: auto;
}
.key {
  width: 2%;
  vertical-align: top;
  position: relative;
  padding: 5px;
  text-align: left;
  padding-right: 18px;
  font-weight: normal;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
}
.collapsed_object {
  cursor: pointer;
}
.label_childrens {
  cursor: pointer;
  + td {
    padding: 1px;
  }
}
.value_childrens {
  padding: 5px !important;
}
.arrow {
  position: absolute;
  right: 6px;
  top: 6px;
  font-size: 9px;
  color: #a1a1a1;
}
</style>

<docs>
**IMPORTANT** This component is created for the **EtsJsonTree**. Don't use this component
</docs>
