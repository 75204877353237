<template>
  <div class="daypicker">
    <div class="cell" :class="{ selected: isSelected(i) }" v-for="(d, i) in dayNames" :key="i" @click="dayClicked(i)">
      <span>{{ d }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EtsDayPicker",
  data: () => ({
    dayNames: ["M", "T", "W", "T", "F"],
    selectedDays: []
  }),
  methods: {
    dayClicked(i) {
      this.selectedDays.indexOf(i) === -1 ? this.selectedDays.push(i) : this.selectedDays.splice(this.selectedDays.indexOf(i), 1);
      /** When the user selects a date
       * @type {object}
       */
      this.$emit("selected-day", this.selectedDays);
    },
    isSelected(i) {
      return this.selectedDays.indexOf(i) === -1 ? false : true;
    }
  }
};
</script>

<style scoped lang="scss">
.daypicker {
  border-spacing: 2px;
}
.cell {
  display: table-cell;
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #fff;
  cursor: pointer;
  border: 2px solid #dadada;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.selected {
  background-color: #dadada;
  &:hover {
    background-color: #dadada;
  }
}
</style>

<docs>
```vue
<ets-day-picker />
```
</docs>
