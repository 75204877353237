<template>
  <header class="ets-header">
    <section class="main">
      <h1 class="title">
        <!-- @slot Use this slot to place the title. slot by default -->
        <slot />
      </h1>
      <div class="subtitle">
        <!-- @slot Use this slot to place the subtitle under title -->
        <slot name="subtitle" />
      </div>
    </section>
    <section class="tools">
      <!-- @slot Use this slot to place buttons in the right side -->
      <slot name="tools" />
    </section>
  </header>
</template>

<script>
export default {
  name: "EtsHeader"
};
</script>

<style lang="scss" scoped>
.ets-header {
  background: $color-grey6;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  /* height: 8.3rem; */
  padding: 1rem 2rem 1.4rem 2rem;
  position: relative;
  align-items: center;
  z-index: 1;

  // Triangle
  &::after {
    position: absolute;
    left: 5rem;
    top: 100%;
    margin-top: -10px;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: #f7f7f7;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
  }
}
.main {
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  flex: 1;
}
.title {
  font-size: 3rem;
  font-family: $font-title;
  margin: 0;
  align-items: center;
  display: flex;
  > * {
    margin-right: 0.75rem;
  }
}
.subtitle {
  color: #999;
  padding-left: 0.2rem;
  text-transform: uppercase;
  > * {
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0;
  }
}
.tools {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
</style>

<docs>
```jsx
<ets-header>
  Title
  <template slot="subtitle">Subtitle</template>
  <template slot="tools">
    <div>Tools / Buttons</div>
  </template>
</ets-header>
```
</docs>
