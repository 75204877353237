<template>
  <div :class="['box', type]">
    <!-- @slot Use this slot to place the alert content -->
    <slot></slot>
    <ets-link-icon v-if="closable" title="Cerrar" icon-name="close" size="small" @click.native="$emit('close')" />
  </div>
</template>

<script>
import EtsLinkIcon from "@/components/Elements/EtsLinkIcon";

export default {
  name: "EtsAlert",
  components: {
    EtsLinkIcon
  },
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    /**
     * Valid types: `error, info, success, warning`
     */
    type: {
      required: true,
      type: String,
      default: "",
      validator: value => {
        return ["error", "info", "success", "warning"].indexOf(value) !== -1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  padding: 0.75rem 1rem;
  min-height: 2.5rem;
  margin: 2rem 0;
}

.error {
  border: 1px solid;
  border-left-width: 6px;
  color: $color-red;
}

.info {
  border: 1px solid;
  border-left-width: 6px;
  color: $color-blue;
}

.warning {
  border: 1px solid;
  border-left-width: 6px;
  color: $color-yellow;
}

.success {
  border: 1px solid;
  border-left-width: 6px;
  color: $color-green;
}
</style>

<docs>
```jsx
<div>
  <ets-alert type="error">Error alert</ets-alert>
  <ets-alert type="warning">Warning alert</ets-alert>
  <ets-alert type="success">Success alert</ets-alert>
  <ets-alert type="info">Info alert</ets-alert>
</div>
```
</docs>
