<template>
  <div>
    <slot>
      <p :class="{ show: showError }" class="form-validation">
        {{ name }} field is required
        <span v-if="format" class="format">(format: '{{ format }}')</span>
      </p>
    </slot>
  </div>
</template>

<script>
export default {
  name: "EtsInputValidation",
  props: {
    /**
     * To control when display validation error
     */
    showError: {
      type: Boolean,
      default: false
    },
    /**
     * Label name to display in validation error
     */
    name: {
      type: String,
      default: "above"
    },
    /**
     * To show the format needed in validation error
     */
    format: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.form-validation {
  display: none;
  color: $color-red;
  align-items: center;
  margin: 0;
  padding-left: 2rem;
}
.show {
  display: flex;
}
.format {
  padding-left: 0.5rem;
}
</style>

<docs>
**IMPORTANT** This component is created for the **EtsInput** and similar. Don't use this component
</docs>
