<template>
  <nav role="navigation-main">
    <ul class="ets-header-menu">
      <li v-for="page in pages" :key="page.id" :class="menuClass(page)">
        <span v-if="!page.link" class="nav-item-main-title">{{ page.name }}</span>
        <a v-else :href="page.link" class="nav-item-main-title">{{ page.name }}</a>
        <ul class="ets-header-submenu" v-if="page.childrens">
          <li v-for="children in page.childrens" :key="children.id" :class="{ 'has-separation': children.separation }">
            <span v-if="children.disabled" class="nav-item-link is-disabled">{{ children.name }}</span>
            <a v-else :href="children.link" class="nav-item-link" :target="children.target">{{ children.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import ConfigStore from "@/configStore";

let store = new ConfigStore();

export default {
  name: "EtsNavBarMenu",
  props: {
    baseUrlOld: {
      type: String,
      required: false
    },
    section: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    intranetUrl() {
      return "//" + this.baseUrlOld;
    },
    developersUrl() {
      let url = store.get();
      return url.urlDevelopers;
    },
    accountUrl() {
      let url = store.get();
      return url.urlAccount;
    },
    eafUrl() {
      let url = store.get();
      return url.urlEAF;
    },
    pages() {
      return [
        {
          name: "Products",
          color: "products",
          childrens: [
            {
              name: "Investment Solutions",
              link: this.intranetUrl + "/Products/Portfolios/PortfolioSelector.aspx"
            },
            {
              name: "Overlays",
              link: this.intranetUrl + "/Products/Overlays/Data/BusinessProcesses.aspx",
              separation: true
            },
            {
              name: "Assets Under Advice",
              link: this.intranetUrl + "/Products/AssetsComparative.aspx"
            }
          ]
        },
        {
          name: "Labs",
          color: "labs",
          childrens: [
            {
              name: "Strategies",
              link: this.intranetUrl + "/Labs/ItWorksItsGood.aspx"
            },
            {
              name: "Comparison",
              link: this.intranetUrl + "/Labs/Comparison/Performance.aspx"
            },
            {
              name: "Papers & Studies",
              link: "http://papers." + this.baseUrlOld
            },
            {
              name: "Challenges",
              link: this.intranetUrl + "/Labs/Challenges/ProcesoInnovacion.aspx"
            }
          ]
        },
        {
          name: "History Data",
          color: "history-data",
          childrens: [
            {
              name: "Asset Managers",
              link: this.intranetUrl + "/HistoryData/FirmManagers/Analysis/FirmManagerSelector.aspx"
            },
            {
              name: "ETFs Issuers",
              link: this.intranetUrl + "/HistoryData/Issuers/IssuerSelector.aspx"
            },
            {
              name: "Markets",
              link: this.intranetUrl + "/HistoryData/Markets/GeneralInformation.aspx"
            },
            {
              name: "Synthetics",
              link: this.intranetUrl + "/HistoryData/Symbols/Synthetics.aspx",
              separation: true
            },
            {
              name: "Updating Processes",
              link: this.intranetUrl + "/HistoryData/UpdateProcesses.aspx",
              separation: false
            },
            {
              name: "Incidences",
              link: this.intranetUrl + "/HistoryData/Incidences.aspx",
              separation: true
            },
            {
              name: "Intraday Data",
              link: this.intranetUrl + "/HistoryData/IntradayData/Tickers.aspx",
              separation: true
            },
            {
              name: "Holidays",
              link: this.intranetUrl + "/HistoryData/Holidays.aspx"
            },
            {
              name: "Data Sources",
              link: this.intranetUrl + "/HistoryData/AnotherSources.aspx"
            },
            {
              name: "Summary",
              link: this.intranetUrl + "/HistoryData/Summary/index.html",
              target: "_blank"
            }
          ]
        },
        {
          name: "Admin",
          color: "admin",
          childrens: [
            {
              name: "Extranet",
              link: this.intranetUrl + "/Admin/Extranet/Users.aspx",
              separation: true
            },
            {
              name: "HHRR",
              disabled: true
            },
            {
              name: "EAF",
              link: this.eafUrl
            }
          ]
        },
        {
          name: "Tech",
          color: "tech",
          childrens: [
            {
              name: "Apps",
              link: this.accountUrl + "/apps"
            },
            {
              name: "Developers",
              link: this.developersUrl
            }
          ]
        },
        {
          name: "Materials",
          color: "materials",
          childrens: [
            {
              name: "Marketing",
              link: "https://sites.google.com/etsfactory.com/theknowledgecenter",
              target: "_blank"
            }
          ]
        }
      ];
    }
  },
  methods: {
    menuClass(page) {
      return [{ selected: page.color === this.section }, `nav-item-main nav-item-main-color-${page.color}`];
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-header-menu {
  align-items: stretch;
  color: #fff;
  display: flex;
  height: 7rem;
  margin: 0;
  padding: 0;
  cursor: default;
  ul {
    padding-left: 0;
  }
  li {
    list-style: none;
  }
}
.nav-item-main {
  align-items: center;
  display: flex;
  position: relative;
  &:hover {
    .ets-header-submenu {
      display: block;
    }
  }
}
.nav-item-main-title {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  color: #fff;
  padding: 0 2.3rem;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
.nav-item-link {
  color: #fff;
  display: block;
  font-size: 1.3rem;
  line-height: 2;
  padding: 0.5rem 2rem;
  text-decoration: none;
  &:hover {
    background-color: #fff;
    text-decoration: none;
  }
}
.ets-header-submenu {
  background-color: inherit;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-top-width: 0.4rem;
  display: none;
  left: 0;
  min-width: 20rem;
  padding: 0.7rem 0;
  position: absolute;
  top: 100%;
  z-index: 10;
  .has-separation {
    &::after {
      background: rgba(0, 0, 0, 0.1);
      content: "";
      display: block;
      height: 0.2rem;
      margin: 0.5rem 1rem;
    }
  }
}
.is-disabled {
  color: #999 !important;
  cursor: default !important;
  outline: none !important;
  text-shadow: 0.1rem 0.1rem 0 #fff !important;
}
.selected::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -1.2rem;
  width: 0;
  border-top: 0;
  border-right: 1.2rem solid transparent;
  border-bottom: 1.2rem solid;
  border-bottom-color: inherit;
  border-left: 1.2rem solid transparent;
}
@each $color, $value in $menu-colors {
  .nav-item-main-color-#{$color} {
    border-bottom-color: $value;
    .nav-item-link:hover {
      color: $value;
    }
    &:hover {
      background-color: $value;
    }
  }
}
</style>

<docs>
```jsx
<div style="height: 300px;">
  <div class="navbar" style="width: 100%; background: #363f48;">
    <ets-nav-bar-menu />
  </div>
</div>
```
</docs>
