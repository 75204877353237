<template>
  <div class="ets-calendar-container">
    <div v-if="!hideButtons" class="selector ets-calendar">
      <div class="buttons-center">
        <ets-button :text="prevText" :disabled="disabledPrev" @click="prevDates" icon="chevron-left" />
        <span :text="currentText" class="current-year-span">
          {{ this.currentText }}
        </span>
        <ets-button :text="nextText" :disabled="disabledNext" @click="nextDates" reversed icon="chevron-right" />
      </div>
      <ets-button :text="todayText" @click="onToday" class="today-button" />
    </div>
    <div class="calendar-wrapper">
      <ets-calendar-month
        v-for="(date, i) in monthsToPrint"
        :key="i"
        @date-clicked="dateClicked"
        class="cal"
        v-bind:style="{ minWidth: columnsStyle }"
        :month="date.month"
        :year="date.year"
        :dates="monthDates(date.month, date.year)"
        :clickableFrom="clickableFrom"
      ></ets-calendar-month>
      <div v-if="legend" class="legend">
        <div class="legend-item" v-for="(item, i) in legend" :key="i">
          <div
            class="legend-circle"
            :style="{
              background: item.color,
              border: '2px solid ' + item.border
            }"
          ></div>
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import EtsCalendarMonth from "@/components/Elements/EtsCalendarMonth";
import EtsButton from "@/components/Elements/EtsButton";

export default {
  name: "EtsCalendarAnnual",
  components: {
    EtsCalendarMonth,
    EtsButton
  },
  props: {
    /**
     * Events to print in the calendar
     */
    dates: {
      type: Array,
      default: () => []
    },
    /**
     * Minimum date the user can click
     */
    clickableFrom: {
      type: Date,
      default: null
    },
    /**
     * Last date month to print
     */
    endDate: {
      type: Date,
      default: () => new Date(new Date().getFullYear(), 11, 31)
    },
    /**
     * Number of months to print
     */
    monthsNumber: {
      type: Number,
      default: 12
    },
    /**
     * Number of months change when the user clicks in the buttons
     */
    steps: {
      type: Number,
      default: 12
    },
    /**
     * To hide the prev, next and today buttons
     */
    hideButtons: {
      type: Boolean,
      default: false
    },
    /**
     * The config of the legend
     */
    legend: {
      type: Array,
      default: () => []
    },
    /**
     * To customize prev button
     */
    prevText: {
      type: String,
      default: ""
    },
    /**
     * To customize today button
     */
    todayText: {
      type: String,
      default: "Today"
    },
    /**
     * To customize next button
     */
    nextText: {
      type: String,
      default: ""
    },
    /**
     * To customize current span
     */
    currentText: {
      type: String,
      default: "Current Year"
    },
    /**
     * To know the oldest date to draw
     */
    initialDrawDate: {
      type: Date,
      default: null
    },
    /**
     * To know the farest date to draw
     */
    finalDrawDate: {
      type: Date,
      default: null
    }
  },
  data: () => ({
    year: new Date().getFullYear(),
    monthsToPrint: [],
    lastDate: null,
    lastMonth: null,
    lastYear: null
  }),
  computed: {
    firstDateOfYear() {
      return new Date(this.year, 0, 1);
    },
    lastDateOfYear() {
      return new Date(this.year + 1, 0, 0);
    },
    columnsStyle() {
      return this.monthsNumber < 4 ? null : "25%";
    },

    disabledPrev() {
      const firstDate = dayjs(this.lastDate).subtract(this.monthsNumber, "month");
      return dayjs(this.initialDrawDate) > firstDate.endOf("month");
    },

    disabledNext() {
      return dayjs(this.finalDrawDate) < dayjs(this.lastDate).add(1, "day").startOf("month");
    }
  },
  methods: {
    onToday() {
      this.lastDate = new Date();
      /**
       * When the user clicks in the today button
       */
      this.$emit("today");
      this.calculateMonths();
    },
    calculateMonths() {
      let dates = [];
      for (let i = this.monthsNumber - 1; i >= 0; i--) {
        let newDate = this.calculatePrevDates(i);
        dates.push({ month: newDate.month, year: newDate.year });
      }
      this.monthsToPrint = dates;
    },
    prevDates() {
      this.lastDate = dayjs(this.lastDate).subtract(this.steps, "months").endOf("month").toDate();
      /**
       * When the user clicks in the prev button. Returns last date
       *
       * @type {object}
       */
      this.$emit("prev", this.lastDate);
      this.calculateMonths();
    },
    nextDates() {
      this.lastDate = dayjs(this.lastDate).add(this.steps, "months").endOf("month").toDate();
      /**
       * When the user clicks in the next button. Returns last date
       *
       * @type {object}
       */
      this.$emit("next", this.lastDate);
      this.calculateMonths();
    },
    calculatePrevDates(steps) {
      let date = this.lastDate;
      let month = date.getMonth();
      let year = date.getFullYear();
      let minus = null;
      if (month - steps < 0) {
        minus = steps - month;
        year -= 1;
        month = 12;
      }
      month -= minus ? minus : steps;
      return { month: month, year: year };
    },
    monthDates(month, year) {
      const firstMonthDay = new Date(year, month, 1);
      const lastMonthDay = new Date(year, month + 1, 0);
      const filtered = this.dates.filter(x => x.date.getTime() >= firstMonthDay && x.date.getTime() <= lastMonthDay);
      return filtered;
    },
    dateClicked(date) {
      /**
       * When the user clicks in a date
       *
       * @event date-clicked
       * @type {object}
       */
      this.$emit("date-clicked", date);
    },
    updateLastDate() {
      this.lastDate = this.endDate;
      this.lastYear = this.endDate.getFullYear();
      this.lastMonth = this.endDate.getMonth();
      this.calculateMonths();
    }
  },
  created() {
    this.updateLastDate();
  },
  watch: {
    endDate() {
      this.updateLastDate();
    },
    year() {
      /**
       * When the user year has change
       *
       * @event year-changed
       * @type {object}
       */
      this.$emit("year-changed", this.year);
    }
  }
};
</script>

<style lang="scss" scoped>
.selector {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  align-items: center;
}
.buttons-center {
  display: flex;
  align-items: center;
}
.button-align {
  vertical-align: top;
}
.calendar-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  .cal {
    display: flex;
    justify-content: center;
    flex-basis: 1;
    padding: 1.5rem 1rem;
  }
}
.year {
  padding: 0px 10px;
}
.ets-calendar-container {
  width: 100%;
}
.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 1rem 3.2rem;
  margin-top: 2rem;
}
.legend-item {
  margin: 0 2rem;
  display: flex;
}
.legend-circle {
  margin-top: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 0.6rem;
}
.today-button {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 2.4rem;
}
.current-year-span {
  @include font-header-l;
  color: #757575;
  margin: 0 10px;
}
.next-button {
  ::v-deep .button-content {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>

<docs>
Under the hood this component uses the **EtsCalendarMonth** component.

Dates prop example:
```js static
const dates = [
  {
    date: new Date(2019, 10, 10),
    cssClass: "date1",
    offset: 6,
    badges: [
      { color: "blue", text: "Reco" },
      { color: "red", text: "Validation" }
    ]
  },
  {
    date: new Date(2019, 10, 9),
    offset: 6,
    cssClass: "date2",
    badges: [{ color: "blue", title: "Validations", text: "Validation" }]
  }
];
```
Legend prop example:

```js static
const legend = [
  { border: "#c44d58", name: "Recos" },
  { border: "#50d466", name: "Prices" }
];
```

```jsx
const legend = [
  { border: "#c44d58", name: "Recos" },
  { border: "#50d466", name: "Prices" }
];
<ets-calendar-annual :legend="legend" width="1300px"/>
```
</docs>
