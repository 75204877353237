<template>
  <div class="star-rating">
    <label
      class="star-rating__star"
      v-for="(rating, i) in ratings"
      :key="i"
      :class="{ 'is-selected': aux_value >= rating && aux_value != null, 'is-disabled': disabled }"
      @mouseover="star_over(rating)"
      @mouseout="star_out"
    >
      <input
        class="star-rating star-rating__checkbox"
        type="radio"
        :value="rating"
        v-model="aux_value"
        @click="set(rating)"
        :disabled="disabled"
      />
      <ets-icon :width="size" :height="size" v-if="aux_value >= rating" icon="star" class="icon" />
      <ets-icon v-else :width="size" :height="size" icon="starempty" class="icon" />
    </label>
  </div>
</template>

<script>
import EtsIcon from "@/components/Elements/EtsIcon";

export default {
  name: "EtsStars",
  components: {
    EtsIcon
  },
  props: {
    /**
     * Initial value
     */
    value: {
      type: Number,
      default: null
    },
    /**
     * The user cannot click
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Size of the icons. Width and height the same
     */
    size: {
      type: String,
      default: "20px"
    },
    /**
     * The score remains when the user clicks
     */
    remains: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    aux_value: null,
    temp_value: null,
    ratings: [1, 2, 3, 4, 5]
  }),
  methods: {
    star_over(index) {
      if (!this.disabled) {
        this.temp_value = this.aux_value;
        return (this.aux_value = index);
      }
    },
    star_out() {
      if (!this.disabled) {
        return (this.aux_value = this.temp_value);
      }
    },
    set(value) {
      if (!this.disabled) {
        if (!this.remains) {
          /** When the user clicks in the star
           * @type {object}
           */
          this.$emit("click", value);
        } else {
          this.$emit("click", value);
          this.temp_value = value;
          return (this.aux_value = value);
        }
      }
    }
  },
  created() {
    this.aux_value = this.value;
  },
  watch: {
    value(newValue) {
      this.aux_value = newValue;
      this.temp_value = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.star-rating {
  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;
    color: #ababab;
    transition: color 0.2s ease-out;

    &:hover {
      cursor: pointer;
    }

    &.is-selected ::v-deep .icon path {
      fill: #ffc107;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}
.star-rating__checkbox {
  display: none;
}
</style>

<docs>
This component is used to display 5 starts to display score.
The component can be used to change the score.

Default:
```
<ets-stars />
```

With initial value:
```
<ets-stars :value="4"/>
```

Disabled:
```
<ets-stars disabled />
```

The score remains on click:
```
<ets-stars remains />
```

More size:
```
<ets-stars size="30px" />
```
</docs>
