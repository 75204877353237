<template>
  <component :is="`Icon${icon}`" v-bind="$props" v-on="$listeners" />
</template>

<script>
export default {
  name: "EtsIcon",
  props: {
    /**
     * The name of the icon to display
     */
    icon: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.main > path {
  fill: $color-blue;
}
</style>

<docs>

**NOTE**: Take a look to the src/assets/icons folder where all the icons are located. The name of the icon is the name of the file.

```jsx
<ets-icon icon="user" />
```

</docs>
