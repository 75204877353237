import dayjs from "dayjs";
import timeago from "timeago.js";

export function dateTime(value, format = "dddd DD MMM YYYY HH:mm:ss") {
  return dayjs(value).format(format);
}
export function date(value, format = "D MMM YYYY") {
  return dayjs(value).format(format);
}
export function time(value, format = "HH:mm:ss") {
  return dayjs(value).format(format);
}
export function dateAgo(value) {
  return timeago().format(new Date(value));
}
