<template>
  <div class="ets-status" :style="{ backgroundColor: color }" :title="tooltip"></div>
</template>

<script>
export default {
  name: "EtsStatus",
  props: {
    /**
     * The color of the circle
     */
    color: {
      type: String,
      default: ""
    },
    /**
     * The text of the tooltip
     */
    tooltip: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-status {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #ddd;
}
</style>

<docs>
```jsx
  <ets-status color="red" tooltip="red"></ets-status>
  <ets-status color="blue" tooltip="blue"></ets-status>
  <ets-status color="green" tooltip="green"></ets-status>
```
</docs>
