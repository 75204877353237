<template>
  <a :class="computedClass">
    <ets-icon class="icon" :icon="iconName" />
  </a>
</template>

<script>
import EtsIcon from "@/components/Elements/EtsIcon";
export default {
  name: "EtsLinkIcon",
  components: {
    EtsIcon
  },
  props: {
    iconName: {
      type: String,
      required: true
    },
    iconColorHover: {
      type: String,
      required: false
    },
    size: {
      type: String
    },
    disableHover: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedClass() {
      let classArray = `ets-link-icon icon-${this.iconName}`;
      if (this.iconColorHover) {
        classArray += ` icon-color-hover-${this.iconColorHover}`;
      }
      if (this.disableHover) {
        classArray += ` icon-disabled`;
      }
      if (this.size) {
        classArray += ` ${this.size}`;
      }
      return classArray;
    }
  }
};
</script>

<style lang="scss">
a:hover {
  text-decoration: none;
}
</style>

<style lang="scss" scoped>
.ets-link-icon {
  // Display & Box Model
  $size: 3.5rem;
  display: inline-flex;
  height: $size;
  width: $size;

  // Position
  align-items: center;
  flex-flow: row;
  justify-content: center;

  // Text
  color: #9a9fa3;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 3.6rem;
  text-decoration: none;

  // Animation
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    color: #fff;
  }

  &.small {
    $size: 2rem;
    font-size: 1rem;
    height: $size;
    width: $size;
    font-weight: lighter;
    &:hover {
      cursor: pointer;
      color: inherit;
    }
  }

  & .icon {
    fill: #9a9fa3;
  }
}

.icon-disabled:hover {
  color: inherit;
  cursor: inherit;
}

@each $color, $value in $icon-colors {
  .icon-color-hover-#{$color} {
    &:hover {
      background-color: $value;
      & .icon {
        fill: #fff;
      }
    }
  }
}
</style>

<docs>
**CAUTION**: This is a temporarily component only to display the links of the navbar. This component needs
to be deleted to use the new EtsIcon component in the navbar
</docs>
