<template>
  <div v-fragment><slot /></div>
</template>

<script>
import fragment from "@/directives/EtsFragment.js";

export default {
  name: "EtsFragment",
  directives: { fragment }
};
</script>

<style></style>

<docs>
Este componente lo que hace es permitir que el componente tenga varios hijos directos, es decir, cuando
haces:

```html static
<template>
  <ets-fragment>
   <p>Hijo 1</p>
   <p>Hijo 2</p>
  </ets-fragment>
</template>
```
Cuando se cargue el componente del fragment, el div que tiene desaparecerá quedando los dos párrafos como
hijos directos del componente, cosa que no está permitida en Vue
</docs>
