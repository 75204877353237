let instance = null;

class ConfigStore {
  constructor() {
    if (!instance) {
      instance = this;
    }

    this._data = {};

    return instance;
  }
  set(value) {
    this._data = value;
  }
  get() {
    return this._data;
  }
}

export default ConfigStore;
