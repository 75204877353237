<template>
  <a :href="'//' + baseUrlOld" class="ets-logo">
    <img alt="ETS | Asset Management Factory" src="@/assets/logo.png" class="app-logo-image" />
  </a>
</template>

<script>
export default {
  name: "EtsNavBarLogo",
  props: {
    baseUrlOld: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-logo {
  align-items: center;
  display: flex;
  padding-left: 1.5rem;
  width: 25rem;
}
.ets-logo-image {
  height: 4.7rem;
  width: 15rem;
}
</style>

<docs>
```jsx
<div class="navbar" style="width: 100%; background: #363f48;">
  <ets-nav-bar-logo />
</div>
```
</docs>
