<template>
  <div class="ets-module">
    <header class="header">
      <section class="title">
        <h3 class="text header-l">
          {{ title }}
          <ets-spinner v-if="loading" class="spinner" inline center :size="25"></ets-spinner>
        </h3>
      </section>
      <section class="tools">
        <slot name="tools" />
      </section>
    </header>
    <section>
      <slot />
    </section>
  </div>
</template>

<script>
import EtsSpinner from "@/components/Elements/EtsSpinner";

export default {
  name: "EtsModule",
  components: {
    EtsSpinner
  },
  props: {
    /**
     * The title of the section
     */
    title: {
      type: String,
      required: true
    },
    /**
     * To display a spinner if the section is loading
     */
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.ets-module {
  margin-bottom: 2.5rem;
}
.text {
  color: #4f4f4f;
  font-weight: bold;
}
.spinner {
  margin-left: 1rem;
}
.tools {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.title {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
<docs>
```jsx
<ets-module title="Section title">
  <p>The content of the section</p>
</ets-module>
```

```jsx
<ets-module title="Section loading" loading>
  <p>The content of the section</p>
</ets-module>
```
</docs>
